import { ErrorBus } from '../../../../_shared';
import { useState, useEffect, useCallback } from 'react';
import { getDTInventoryRecords } from '../../../../../services/service/services';
import { Inventory } from '../../../../../services/inventory/types';

export interface UseDTInventoryRecords {
  dtInventoryRecords?: Inventory[];
  refetchDTInventoryRecords: () => void;
}

interface UseInventoryRecordsProps {
  sku?: string;
  errorBus: ErrorBus;
}

const busKey = 'useInventoryRecords';

const useDTInventoryRecords = ({
  sku,
  errorBus,
}: UseInventoryRecordsProps): UseDTInventoryRecords => {
  const [dtInventoryRecords, setDTInventoryRecords] = useState<
    UseDTInventoryRecords['dtInventoryRecords']
  >();
  const { setError } = errorBus;

  const refetchDTInventoryRecords = useCallback(() => {
    setError(busKey, undefined);
    if (sku) {
      getDTInventoryRecords(sku, {
        onSuccess: setDTInventoryRecords,
        catchFailure: error => setError(busKey, error),
      });
    }
  }, [sku, setError]);

  useEffect(refetchDTInventoryRecords, [refetchDTInventoryRecords]);

  return {
    dtInventoryRecords,
    refetchDTInventoryRecords,
  };
};

export { useDTInventoryRecords };
