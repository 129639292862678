import React, { useState, useEffect } from 'react';
import { Alert as MuiAlert, AlertProps as MuiAlertProps } from '@mui/material';

interface UseAutoCloseAlert {
  autoCloseAlertOpen: boolean;
  handleAutoCloseAlertOpen(): void;
  handleAutoCloseAlertClose(): void;
}

export const useAutoCloseAlert = (open = false): UseAutoCloseAlert => {
  const [autoCloseAlertOpen, setAutoCloseAlertOpen] = useState(open);

  return {
    autoCloseAlertOpen,
    handleAutoCloseAlertOpen: () => setAutoCloseAlertOpen(true),
    handleAutoCloseAlertClose: () => setAutoCloseAlertOpen(false),
  };
};

export type AlertProps = MuiAlertProps & {
  open: boolean;
  handleClose(): void;
  autoHideDuration?: number;
};

export const AutoCloseAlert: React.FC<AlertProps> = ({
  open,
  handleClose,
  autoHideDuration = 6000,
  children,
  ...muiProps
}) => {
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleClose();
    }, autoHideDuration); // Adjust the timeout as needed (in milliseconds)

    return () => clearTimeout(timeoutId); // Clear timeout on unmount
  }, [autoHideDuration, handleClose]);

  return open ? (
    <MuiAlert onClose={handleClose} {...muiProps}>
      {children}
    </MuiAlert>
  ) : null;
};
