import React from 'react';
import { Autocomplete, AutocompleteProps } from '../library';
import { WarehouseBin } from '../../services/inventory/types';

interface WarehouseBinProps {
  label?: string;
  placeholder?: string;
  // options to be displayed in the Autocomplete dropdown.
  // these will change as the user's typing matches additional locations.
  options: WarehouseBin[];

  // the current typeahead value in the Autocomplete (just the string of characters)
  typeaheadVal: string;
  onTypeaheadChange: (inputVal: string) => any;

  // the current selected bin value (the entire Bin object)
  selectedBin: WarehouseBin | undefined;
  onBinChange: (selectedBin: WarehouseBin | undefined) => any;

  // error to display if there is one
  errorMsg?: string;
}

// omit properties that this component is in charge of (i.e. won't be passed through to the Autocomplete)
export type WarehouseBinAutocompleteProps = WarehouseBinProps &
  Omit<
    AutocompleteProps<WarehouseBin>,
    | 'inputProps'
    | 'blurOnSelect'
    | 'getOptionLabel'
    | 'getOptionSelected'
    | 'noOptionsText'
    | 'value'
    | 'onChange'
    | 'placeholder'
    | 'options'
  >;

export const WarehouseBinAutocomplete: React.FC<WarehouseBinAutocompleteProps> = ({
  label,
  placeholder,
  options,
  typeaheadVal,
  onTypeaheadChange,
  selectedBin,
  onBinChange,
  errorMsg,
  ...muiProps
}) => {
  return (
    <Autocomplete<WarehouseBin>
      blurOnSelect
      inputProps={{
        label: label || 'Bin',
        variant: 'outlined',
        value: typeaheadVal,
        onChange: e => onTypeaheadChange(e.target.value.trim()),
        placeholder: placeholder || 'Enter bin',
        error: !!errorMsg,
        helperText: errorMsg ?? null,
      }}
      getOptionLabel={option => option.code}
      getOptionSelected={(option, value) => option.id === value.id}
      noOptionsText={'No bins match entered text'}
      value={selectedBin || null}
      onChange={(_e, newValue) => {
        onBinChange(newValue ?? undefined);
        if (newValue) {
          onTypeaheadChange(newValue?.code);
        }
      }}
      placeholder="Bin"
      options={options}
      {...muiProps}
    />
  );
};
