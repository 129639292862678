import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { API_URL } from '../../../constants';
import axios from '../../../utils/axios';
import { FormatMoney } from '../../../utils/helper';
import imagePlaceholder from '../../../images/drive-side-photo.svg';
import Modal from '../../../components/modals/genericFormModal';
import SkuRelocator from '../../service/relocator/SkuRelocate';
import Loader from '../../../components/loader';
import Barcode from '../../../components/barcode.tsx';
import DepreciationTable from './depreciationTable';
import CpoSubmission from './cpoSubmission';
import ImageModal from '../../../components/catalog/ImageModal';
import { SUBMISSION_DETAIL_ROUTE } from '../../tradeups/SubmissionDetailView';
import { PARTNER_SUBMISSION_DETAIL_ROUTE } from '../../tradeups/partnerDetaiView';
import styles from '../../../styledComponents';
import { PageWrapper } from '../../../styledComponents/wrappers';
import RefurbishedItemRelocator from '../../inventory/relocate/ReburbishedItemRelocator';
const { StripedDataGrid } = styles;

class ThreeSixtyDetailView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: {},
      productInfo: {},
      inspection: {},
      inspections: [],
      depreciation: [],
      cpoSubmission: {},
      locations: [],
      submission: {},
      threeSixty: {},
      image: '',
      displayModal: false,
      events: [],
      valueAtSubmissionTime: { month: 0 },
      currentValue: {},
      pipeline: '',
      displayRelocateModal: false,
      loading: true,
      parts: [],
      currentSalePriceDepreciationSchedule: [],
    };

    this.eventColumns = [
      {
        headerName: 'Event',
        field: 'event',
        flex: 2,
      },
      {
        headerName: 'User',
        field: 'user',
        flex: 1,
      },
      {
        headerName: 'Event Date',
        valueFormatter: ({ value }) =>
          new Date(value).toLocaleString('en-US', { timeZone: 'America/Denver' }),
        field: 'created',
        flex: 2,
        id: 'eventDate',
      },
    ];

    this.purchaseColumns = [
      {
        headerName: 'PO Number',
        field: 'po_number',
        flex: 1,
      },
      {
        headerName: 'PO Type',
        field: 'purchase_type',
        flex: 1,
      },
      {
        headerName: 'PO Amount',
        field: 'po_amount',
        flex: 1,
        valueFormatter: ({ value }) => (value ? `$${FormatMoney(value)}` : ''),
      },
      {
        headerName: 'Item Cost',
        field: 'unit_price',
        flex: 1,
        valueFormatter: ({ value }) => (value ? `$${FormatMoney(value)}` : ''),
      },
      {
        headerName: 'Buyer',
        field: 'buyer',
        flex: 1,
      },
      {
        headerName: 'Created On',
        field: 'created',
        flex: 1,
        valueFormatter: ({ value }) =>
          new Date(value).toLocaleDateString('en-US', { timeZone: 'America/Denver' }),
      },
    ];

    this.locationColumns = [
      {
        headerName: 'Location',
        field: 'location',
        flex: 1,
      },
      {
        headerName: 'Bin',
        field: 'bin',
        flex: 1,
      },
      {
        headerName: 'Quantity',
        field: 'quantity',
        flex: 1,
      },
    ];

    this.salesColumns = [
      {
        headerName: 'SO',
        field: 'buyer',
        flex: 1,
      },
      {
        headerName: 'Shipping Name',
        valueGetter: params => {
          return `${params.row.shipping_first_name || ''} ${params.row.shipping_last_name || ''}`;
        },
        id: 'shippingName',
        flex: 1,
      },
      {
        headerName: 'Order Total',
        field: 'order_total',
        flex: 1,
      },
      {
        headerName: 'Qty',
        field: 'quantity',
        flex: 0.5,
      },
      {
        headerName: 'Shipping Phone',
        field: 'shipping_day_phone',
        flex: 1,
      },
      {
        headerName: 'Ship Price',
        field: 'total_shipping_price',
        flex: 1,
      },
      {
        headerName: 'Unit Price',
        field: 'unit_price',
        flex: 1,
      },
      {
        headerName: 'Order Date',
        field: 'order_date',
        valueFormatter: ({ value }) =>
          new Date(value).toLocaleDateString('en-US', { timeZone: 'America/Denver' }),
        flex: 1,
      },
    ];
  }

  componentDidMount() {
    document.addEventListener('keydown', this._handleEscKey, false);
    this.fetchProduct();
    this.fetchEvents();
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this._handleEscKey, false);
  }

  UNSAFE_componentWillMount() {
    document.addEventListener('keydown', this._handleEscKey, false);
  }

  _handleEscKey = e => {
    if (e.keyCode === 27) {
      this.setState({ displayModal: false, displayRelocateModal: false });
    }
  };

  fetchCurrentValue = async sku => {
    const { id } = this.props.match.params;
    const url = `${API_URL}/cpo/currentValue/${id}`;
    const response = await axios.get(url);
    this.setState({
      currentValue: response.data,
    });
  };

  fetchEvents = async () => {
    const { id } = this.props.match.params;
    const response = await axios.get(`${API_URL}/products/360Events/${id}`);
    this.setState({ events: response.data });
  };

  fetchProduct = async () => {
    try {
      const { id } = this.props.match.params;
      const response = await axios.get(`${API_URL}/products/360/${id}`);
      const {
        purchases,
        sales,
        product,
        inspections,
        depreciation,
        valueAtSubmissionTime,
        parts,
        cpoSubmission,
        submission,
        threeSixty,
        pipeline,
        currentSalePriceDepreciationSchedule,
        locations,
      } = response.data;
      if (
        inspections[0] &&
        sales.length > 0 &&
        inspections[0].service_level === 'CPO' &&
        product.certified_pre_owned
      ) {
        this.fetchCurrentValue();
      }

      const image = product.first_image_url || '';
      this.setState({
        productInfo: product,
        purchases,
        locations,
        sales,
        inspection: inspections[0],
        inspections,
        depreciation,
        valueAtSubmissionTime,
        cpoSubmission,
        submission,
        threeSixty,
        image: image,
        pipeline,
        parts,
        currentSalePriceDepreciationSchedule,
      });
    } catch (err) {
      console.error(err);
    } finally {
      this.setState({ loading: false });
    }
  };

  breakUpArray(array, size) {
    const results = [];
    while (array.length) {
      results.push(array.splice(0, size));
    }

    return results;
  }

  displayProduct() {
    const newObj = {
      ...this.state.product,
      ...this.state.productInfo,
      ...this.state.threeSixty,
    };
    const array = Object.entries(newObj);
    const evenLengths = Math.ceil(array.length / 3);
    const entriesArray = this.breakUpArray(array, evenLengths);
    return entriesArray.map(chunk => (
      <div key={chunk} className="one-third-wrap">
        {chunk.map(entry => {
          if (
            entry[0] === 'first_image_url' ||
            entry[0] === 'certified_pre_owned' ||
            entry[0] === 'title' ||
            entry[0] === '_storetitle'
          ) {
            // do nothing
          } else if (
            entry[0] === 'created' ||
            entry[0] === 'internally_updated' ||
            entry[0] === 'last_sale_date_utc'
          ) {
            return (
              <div key={entry[0]} className="three-sixty-wrap">
                <label>
                  {entry[0]
                    .toUpperCase()
                    .split('_')
                    .join(' ')}
                  :
                </label>
                <span>{new Date(entry[1]).toLocaleDateString()}</span>
              </div>
            );
          } else {
            return (
              <div key={entry[0]} className="three-sixty-wrap">
                <label>
                  {entry[0]
                    .toUpperCase()
                    .split('_')
                    .join(' ')}
                  :{' '}
                </label>
                <span>{entry[1] ? entry[1] : 'N/A'}</span>
              </div>
            );
          }
        })}
      </div>
    ));
  }

  displayPhoto() {
    return this.state.productInfo?.first_image_url || imagePlaceholder;
  }

  setTubelessStatus = tubeless => {
    let tubelessStatus;
    if (tubeless === null) {
      tubelessStatus = 'null';
    } else {
      tubelessStatus = tubeless ? 'Tubeless' : 'Not Tubeless';
    }
    return tubelessStatus;
  };

  displayInspection(inspection) {
    const {
      serial_number,
      inspection_date,
      inspector_name,
      mechanic_name,
      is_tubeless,
      mechanic_time_seconds,
      notes,
      video,
      pdf,
      mechanic_notes,
      service_level,
      id,
    } = inspection;

    const list = (
      <div className="full-wrap" key={id}>
        <div style={{ flex: 1 }}>
          <span className="three-sixty-wrap">
            <label>Service Level: </label>
            <span
              style={{
                ...this.displayServiceLevel(inspection),
                padding: '5px',
                borderRadius: '3px',
                color: '#fff',
              }}
            >
              {service_level || ''}
            </span>
          </span>
          <span className="three-sixty-wrap">
            <label>Inspection Date: </label>
            <span>{new Date(inspection_date).toLocaleDateString()}</span>
          </span>
          <span className="three-sixty-wrap">
            <label>INSPECTOR NAME: </label>
            <span>{inspector_name}</span>
          </span>
          <span className="three-sixty-wrap">
            <label>Tubeless: </label>
            <span>{this.setTubelessStatus(is_tubeless)}</span>
          </span>
        </div>
        <div style={{ flex: 1 }}>
          <span className="three-sixty-wrap">
            <label>Mechanic Name: </label>
            <span>{mechanic_name || 'N/A'}</span>
          </span>
          <span className="three-sixty-wrap">
            <label>Mechanic Time: </label>
            <span>
              {mechanic_time_seconds
                ? new Date(mechanic_time_seconds * 1000).toISOString().substr(11, 8)
                : 'N/A'}
            </span>
          </span>
        </div>
        <span className="three-sixty-wrap">
          <label>Inspector Notes: </label>
          <span>{notes}</span>
        </span>
        <span className="three-sixty-wrap">
          <label>Mechanic Notes: </label>
          <span>{mechanic_notes}</span>
        </span>
        <div style={{ flex: 1 }}>
          <span className="three-sixty-wrap">
            <label>Inspection Form: </label>
            <span>
              <a href={pdf}>{pdf || 'N/A'}</a>
            </span>
          </span>
          <span className="three-sixty-wrap">
            <label>CPO Video: </label>
            <span>
              <a href={video}>{video || 'N/A'}</a>
            </span>
          </span>
        </div>
        <span className="three-sixty-wrap">
          <label>Drivetrain Inspection: </label>
          <span>
            <a href={`/service/service-plans/${id}`}>inspection</a>
          </span>
        </span>
        <span className="three-sixty-wrap">
          <label>Inspected Serial #: </label>
          <span>{serial_number}</span>
        </span>
      </div>
    );

    return list;
  }

  displayPurchases() {
    const { purchases } = this.state;
    if (purchases) {
      return (
        <div className="react-table react-table-detail">
          <h1 className="table-title">Purchases</h1>
          <StripedDataGrid
            rows={purchases}
            getRowId={row => row.po_number}
            columns={this.purchaseColumns}
            pageSize={purchases.length}
          />
        </div>
      );
    }
  }

  displayLocations() {
    const { locations } = this.state;
    if (locations) {
      return (
        <div className="react-table react-table-detail">
          <h1 className="table-title">Locations</h1>
          <StripedDataGrid
            rows={locations}
            getRowId={() => Math.floor(Math.random() * 10000)}
            columns={this.locationColumns}
            pageSize={locations.length}
          />
        </div>
      );
    }
  }

  displayEvents() {
    const { events } = this.state;
    if (events.length > 0) {
      return (
        <div className="react-table react-table-detail">
          <h1 className="table-title">Events</h1>
          <StripedDataGrid
            rows={events}
            getRowId={row => Date.parse(row.created)}
            columns={this.eventColumns}
            pageSize={events.length}
          />
        </div>
      );
    }
  }

  displaySales() {
    const { sales } = this.state;
    if (sales) {
      return (
        <div className="react-table react-table-detail">
          <h1 className="table-title">Sales</h1>
          <StripedDataGrid
            rows={sales}
            getRowId={row => row.buyer}
            columns={this.salesColumns}
            pageSize={sales.length}
          />
        </div>
      );
    }
  }

  displaySubmission() {
    const { submission } = this.state;
    return (
      <div className="full-wrap" key={submission.tup_submission_id ?? submission.bd_submission_id}>
        <div style={{ flex: 1 }}>
          {submission.tup_submission_id && (
            <span className="three-sixty-wrap">
              <label>SUBMISSION ID: </label>
              <span>
                <a
                  href={`${SUBMISSION_DETAIL_ROUTE}/${submission.tup_submission_id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {submission.tup_submission_id}
                </a>
              </span>
            </span>
          )}
          {submission.bd_submission_id && (
            <span className="three-sixty-wrap">
              <label>SUBMISSION ID: </label>
              <span>
                <a
                  href={`${PARTNER_SUBMISSION_DETAIL_ROUTE}/${submission.bd_submission_id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {submission.bd_submission_id}
                </a>
              </span>
            </span>
          )}
          <span className="three-sixty-wrap">
            <label>SUBMISSION DATE: </label>
            <span>{new Date(submission.created).toLocaleDateString()}</span>
          </span>
          <span className="three-sixty-wrap">
            <label>STATUS: </label>
            <span>{submission.status}</span>
          </span>
        </div>
        <div style={{ flex: 1 }}>
          <span className="three-sixty-wrap">
            <label>OFFER PRICE: </label>
            <span>{submission.offer_amount}</span>
          </span>
          <span className="three-sixty-wrap">
            <label>EXPECTED PRICE: </label>
            <span>{submission.expected_value}</span>
          </span>
        </div>
        <div style={{ flex: 1 }}>
          <span className="three-sixty-wrap">
            <label>SELLER EMAIL: </label>
            <span>{submission.email}</span>
          </span>
        </div>
      </div>
    );
  }

  displayServiceLevel(inspection) {
    if (inspection) {
      switch (inspection.service_level) {
        case 'CPO':
          return { backgroundColor: '#00c160' };
        case 'PO':
          return { backgroundColor: '#71A5E9' };
        case 'EBAY':
          return { backgroundColor: '#F4C573' };
        default:
          return { backgroundColor: '#fff' };
      }
    }
  }

  displayModal = () => {
    this.setState({ displayModal: !this.state.displayModal });
  };

  render() {
    const {
      inspection,
      inspections,
      displayModal,
      displayRelocateModal,
      threeSixty,
      pipeline,
      cpoSubmission,
      submission,
      image,
      productInfo,
    } = this.state;
    return (
      <PageWrapper style={{ overflow: 'hidden' }} className="three-sixty-page">
        {productInfo && (
          <Fragment>
            <div style={{ position: 'relative' }}>
              {inspection && (
                <p style={this.displayServiceLevel(inspection)} className="service-level-360">
                  {inspection.service_level ? inspection.service_level : ''}
                </p>
              )}
              <img
                className="product-photo"
                src={this.displayPhoto()}
                alt="product"
                onClick={this.displayModal}
                style={{ cursor: 'pointer' }}
              />
              <div className="react-table">
                <h2 className="centered-text">{threeSixty.title}</h2>
                <h3 className="centered-text">Pipeline: {pipeline}</h3>
                {submission && (
                  <span className="flex-column">
                    <button
                      onClick={() => this.setState({ displayRelocateModal: true })}
                      className="classy-button"
                    >
                      Relocate Sku
                    </button>
                  </span>
                )}
              </div>
              <div className="product-info react-table gray">
                {this.displayProduct()}
                {threeSixty.sku && <Barcode text={threeSixty.sku} title={threeSixty.title} />}
              </div>
            </div>
            <div className="react-table">
              {inspections.map(insp => this.displayInspection(insp))}
            </div>
            {submission && !this.state.loading && (
              <div className="submission-wrap">{this.displaySubmission()}</div>
            )}
            {this.displayLocations()}
            {this.displayPurchases()}
            {this.displaySales()}
            {this.displayEvents()}
            <table className="react-table react-table-detail">
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Part Number</th>
                  <th>Cost</th>
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody>
                {this.state.parts.map((p, idx) => (
                  <tr key={idx}>
                    <td>{p.description}</td>
                    <td>{p.part_number}</td>
                    <td>{p.cost}</td>
                    <td>{p.created_at}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {inspection && (
              <div className="react-table gray flex-row-wrap">
                {cpoSubmission && (
                  <CpoSubmission
                    valueAtSubmissionTime={this.state.valueAtSubmissionTime}
                    cpoSubmission={cpoSubmission}
                  />
                )}
                <DepreciationTable
                  title="CPO Depreciation"
                  data={this.state.depreciation.map(v => [v.month, v.amount])}
                  currentValue={this.state.currentValue}
                />
                <DepreciationTable
                  title="Pre Sale Depreciation"
                  data={Object.entries(this.state.currentSalePriceDepreciationSchedule)}
                  currentValue={{ month: null, value: null }}
                />
              </div>
            )}
          </Fragment>
        )}
        <ImageModal displayModal={displayModal} onClose={this.displayModal} imageSrc={image} />
        {displayRelocateModal && (
          <Modal
            success={false}
            error={false}
            form={<RefurbishedItemRelocator sku={threeSixty.sku} />}
            closeModal={() => this.setState({ displayRelocateModal: false })}
          />
        )}
        <Loader loading={this.state.loading} />
      </PageWrapper>
    );
  }
}

ThreeSixtyDetailView.propTypes = {
  history: PropTypes.shape({ length: PropTypes.number, push: PropTypes.func }).isRequired,
  match: PropTypes.shape().isRequired,
};

export default withRouter(ThreeSixtyDetailView);
