import React, { useEffect, useState } from 'react';
import { useLocation, NavLink, Link } from 'react-router-dom';
import { API_URL } from '../../constants';
import Cookies from 'universal-cookie';
import '../../styles/nav.css';
import axios from '../../utils/axios';
import tpcLogo from '../../images/new-logo.svg';
import { logout } from '../../actions';
import VectorDown from './svgs/VectorDown';
import VectorUp from './svgs/VectorUp';
import Logout from './svgs/Logout';
import User from './svgs/User';
import ToggleLeft from './svgs/ToggleLeft';
import ToggleRight from './svgs/ToggleRight';
import TpcLogoCondensed from './svgs/TpcLogoCondensed';
import Paypal from './svgs/Paypal';
import Admin from './svgs/Admin';
import { default as Preferences } from '@material-ui/icons/Build';
import navItems from './nav-data';
import { useSelector, useDispatch } from 'react-redux';
import { GlobalState, Permissions } from '../../reducers/types';
import * as actions from '../../actions';

const subnavs = Object.values(navItems)
  .map(item => item.subNav)
  .flat();

const Nav: React.FC = () => {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const { navExpanded } = useSelector((state: GlobalState) => state.nav);
  const { user } = useSelector((state: GlobalState) => state.auth);
  const setNavExpanded = (v: boolean) => dispatch(actions.setNavExpanded(v));
  const { pathname } = useLocation();
  const permission = JSON.parse(localStorage.getItem('userPermissions') as string);

  const displayAdmin = !!permission?.includes(Permissions.ADMIN_USERS);
  const displayFinance = !!permission?.includes(Permissions.FINANCE_USERS);

  const activeSubnav = subnavs.find(subnav => subnav?.to === pathname);
  const getActiveSection = () => {
    if (pathname === '/paypal') {
      return 'paypal';
    } else if (pathname === '/admin/users') {
      return 'admin';
    } else if (pathname === '/account/home') {
      return 'user';
    }
    return activeSubnav?.parent;
  };
  const [activeSection, setActiveSection] = useState(getActiveSection());
  const getUserData = async () => {
    const token = cookies.get('token');
    const url = `${API_URL}/user/current`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response) {
        dispatch(actions.setUser(response.data));
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogout = () => {
    cookies.remove('token', { path: '/' });
    logout();
    window.location.reload();
  };
  const handleToggle = () => {
    if (navExpanded) {
      setActiveSection(activeSubnav?.parent);
    }
    setNavExpanded(!navExpanded);
  };
  const handleNavbarClick = () => {
    if (!navExpanded) {
      setNavExpanded(true);
    }
  };

  return (
    <div
      onClick={handleNavbarClick}
      className={`navbar ${navExpanded ? 'navbar-expanded' : 'navbar-collapsed'}`}
    >
      <div className="top-content">
        <div className="navbar-header">
          <div>
            <Link to="/" className="nav-logo">
              {navExpanded ? (
                <img alt="The Pro's Closet logo" src={tpcLogo} className="logo-img" />
              ) : (
                <TpcLogoCondensed />
              )}
            </Link>
            <button
              className={`navbar-toggle ${
                navExpanded ? 'navbar-toggle-expanded' : 'navbar-toggle-collapsed'
              }`}
              onClick={handleToggle}
            >
              {navExpanded ? <ToggleLeft /> : <ToggleRight />}
            </button>
          </div>
          {navExpanded && (
            <NavLink to="/forms" className="nav-helpdesk-link">
              Tickets & Requests
            </NavLink>
          )}
        </div>
        <div className="navbar-routes">
          {Object.keys(navItems).map(cat => {
            const displayTitle = navItems[cat]?.displayTitle?.toUpperCase() ?? cat.toUpperCase();
            const active = activeSection?.toLowerCase() == displayTitle?.toLowerCase();
            const Icon = navItems[cat]?.icon;
            return (
              <div
                key={cat}
                className={`navbar-route ${active ? 'active-navbar-route-dropdown' : ''}`}
                onClick={() => {
                  setNavExpanded(true);
                  setActiveSection(active ? undefined : displayTitle);
                }}
              >
                <div className="navbar-title">
                  {<Icon />}
                  {navExpanded && (
                    <>
                      <span className="navbar-title-text">{displayTitle}</span>{' '}
                      {active ? <VectorUp /> : <VectorDown />}
                    </>
                  )}
                </div>
                {navExpanded && active && (
                  <div className="navbar-subroutes">
                    {navItems[cat].subNav?.map(item => {
                      if (item.restrict && !permission.includes(item.restrict)) {
                        return null;
                      }
                      return (
                        <NavLink
                          key={item.to}
                          to={item.to || '/'}
                          className={`navbar-subroute ${
                            activeSubnav?.name === item.name ? 'active-navbar-subroute' : ''
                          }`}
                        >
                          {item.name}
                        </NavLink>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
          <div className={`navbar-divider ${navExpanded ? 'navbar-divider-expanded' : ''}`} />
          {displayFinance && (
            <NavLink
              to="/paypal"
              className={`navbar-route ${activeSection === 'paypal' ? 'active-navbar-route' : ''}`}
            >
              <Paypal />
              {navExpanded && <span className="navbar-title-text">paypal</span>}
            </NavLink>
          )}
          {displayAdmin && (
            <NavLink
              to="/admin/users"
              className={`navbar-route ${activeSection === 'admin' ? 'active-navbar-route' : ''}`}
            >
              <Admin />
              {navExpanded && <span className="navbar-title-text">admin</span>}
            </NavLink>
          )}
          <NavLink
            to="/preferences"
            className={`navbar-route ${
              activeSection === 'preferences' ? 'active-navbar-route' : ''
            }`}
          >
            <Preferences fontSize="inherit" />
            {navExpanded && <span className="navbar-title-text">preferences</span>}
          </NavLink>
        </div>
      </div>
      <div className="navbar-user">
        <div onClick={handleLogout} className="navbar-route">
          <Logout />
          {navExpanded && <span className="navbar-title-text">LOGOUT</span>}
        </div>
        <NavLink
          to="/account/home"
          className={`navbar-route ${activeSection === 'user' ? 'active-navbar-route' : ''}`}
        >
          <User />
          {navExpanded && <span className="navbar-title-text">{user?.name}</span>}
        </NavLink>
      </div>
    </div>
  );
};

export default Nav;
