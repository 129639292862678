import React, { FC, useCallback, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';
import axios from '../../../utils/axios';
import { API_URL } from '../../../constants';
import styles from '../../../styledComponents';
import {
  ErrorMessage,
  TextField,
  Button,
  LoaderOverlay,
  Checkbox,
} from '../../../components/library';
import { AutoCloseAlert, useAutoCloseAlert } from '../../../components/library/AutoCloseAlert';
import { Alert, AlertTitle, Typography } from '@mui/material';
import { UpcSearchModal } from '../../service/UpcSearchModal';
import { useInventory } from '../../catalog/utils/useInventory';
import { Inventory, WarehouseBin } from '../../../services/inventory/types';
import { WarehouseBinAutocomplete } from '../../../components/inventory/WarehouseBinAutocomplete';
import { Box } from '@material-ui/core';
import { getZoneParentById } from '../../catalog/utils/inventory/utils';

export const REFURBISHED_ITEM_RELOCATOR_ROUTE = '/inventory/relocate/refurbished-item';

const { PageWrapper } = styles;

const InlineTextField = styled(TextField)`
  display: inline-block;
`;

const CheckboxWrapper = styled.label`
  display: inline-block;
  cursor: pointer;
  min-width: 160px;
`;

interface SkuRelocatorProps {
  sku?: string;
}

const RefurbishedItemRelocator: FC<Partial<RouteComponentProps> & SkuRelocatorProps> = ({
  location: windowLocation,
  sku: propSku,
}) => {
  const skuParam = windowLocation ? new URLSearchParams(windowLocation.search).get('sku') : null;
  // Can pass in initial sku as a query param or prop, prefer query param
  const initialSku = skuParam || propSku || '';

  const [loading, setLoading] = useState<boolean>(false);
  const [upcModalOpen, setUpcModalOpen] = useState(false);

  const [warehouseZoneLocked, setWarehouseZoneLocked] = useState<boolean>(false);
  const [binLocked, setBinLocked] = useState<boolean>(false);
  const [inspectionStatusLocked, setInspectionStatusLocked] = useState<boolean>(false);

  const [sku, setSku] = useState<string>(initialSku);
  const [bin, setBin] = useState<WarehouseBin | undefined>(undefined);
  const [binTypeahead, setBinTypeahead] = useState<string>('');
  const [zoneId, setZoneId] = useState<number | undefined>(undefined);
  const [inspectionStatusId, setInspectionStatusId] = useState<string>('');
  const [currentInventoryDetails, setCurrentInventoryDetails] = useState<Inventory | undefined>(
    undefined,
  );
  const [isSnowflakeSku, setIsSnowflakeSku] = useState<boolean>(false);

  const {
    autoCloseAlertOpen: successAutoCloseAlertOpen,
    handleAutoCloseAlertOpen: handleSuccessAutoCloseAlertOpen,
    handleAutoCloseAlertClose: handleSuccessAutoCloseAlertClose,
  } = useAutoCloseAlert();
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
  const onSuccessAutoAlertClose = useCallback(() => {
    setSuccessMessage(undefined);
    handleSuccessAutoCloseAlertClose();
  }, [setSuccessMessage, handleSuccessAutoCloseAlertClose]);

  const {
    autoCloseAlertOpen: errorAutoCloseAlertOpen,
    handleAutoCloseAlertOpen: handleErrorAutoCloseAlertOpen,
    handleAutoCloseAlertClose: handleErrorAutoCloseAlertClose,
  } = useAutoCloseAlert();

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const onErrorAutoAlertClose = useCallback(() => {
    setErrorMessage(undefined);
    handleErrorAutoCloseAlertClose();
  }, [setErrorMessage, handleErrorAutoCloseAlertClose]);

  const {
    warehouseZones,
    warehouseBinsForZone,
    inspectionStatuses,
    error: inventoryHookError,
  } = useInventory({
    typeaheadVal: binTypeahead,
    zoneId: zoneId,
  });

  const clearState = () => {
    if (!warehouseZoneLocked) setZoneId(undefined);
    if (!binLocked) setBin(undefined);
    if (!inspectionStatusLocked) setInspectionStatusId('');
    setSku('');
    setLoading(false);
    setErrorMessage(undefined);
    handleErrorAutoCloseAlertOpen();
    setCurrentInventoryDetails(undefined);
  };

  const getCurrentSkuInventoryDetails = async () => {
    if (sku) {
      try {
        await axios.get(`${API_URL}/inventory/skus/${sku}/inventoryRecords`).then(res => {
          const currentInvDetails = res?.data?.[0];
          setIsSnowflakeSku(!!currentInvDetails?.inspection);
          setCurrentInventoryDetails(currentInvDetails);
        });
      } catch (err) {
        console.error(
          err?.response?.data?.message
            ? err.response.data.message
            : err?.message
            ? err?.message
            : 'An unknown error occurred',
        );
      }
    }
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    try {
      setLoading(true);
      let successMessage: string;

      if (!bin || !zoneId) {
        throw new Error('A warehouse zone and bin are required');
      }

      await axios
        .post(`${API_URL}/inventory/skus/${sku}/relocate`, {
          toZoneId: zoneId,
          toBin: bin.code,
          toInspectionStatusId: inspectionStatusId,
        })
        .then(() => {
          const inspectionStatusUpdateMsg =
            !!inspectionStatusId &&
            currentInventoryDetails?.inspection?.statusId !== parseInt(inspectionStatusId, 10)
              ? `and Inspection Status from ${
                  currentInventoryDetails?.inspection?.status?.name
                } to ${
                  inspectionStatuses?.find(status => status.id === parseInt(inspectionStatusId, 10))
                    ?.name
                }.`
              : '';
          successMessage = `Relocation of ${sku} from ${currentInventoryDetails?.warehouseBin?.code} to ${bin?.code} ${inspectionStatusUpdateMsg} completed.`;
          setSuccessMessage(successMessage);
          handleSuccessAutoCloseAlertOpen();
          clearState();
        });
    } catch (err) {
      setErrorMessage(
        err?.response?.data?.message
          ? err.response.data.message
          : err?.message
          ? err?.message
          : 'An unknown error occurred',
      );
      handleErrorAutoCloseAlertOpen();
    } finally {
      setLoading(false);
    }
  };

  const handleSkuByUpc = (value: string) => {
    setUpcModalOpen(false);
    setSku(value);
  };

  const skuInputRef = React.useRef<HTMLInputElement>(null);

  const theme = useTheme();
  return (
    <PageWrapper>
      <Box
        style={{
          width: '100%',
          maxWidth: '500px',
          marginTop: theme.spacing(8),
        }}
      >
        <LoaderOverlay loading={loading} />
        <Typography variant="h4" gutterBottom>
          Single Item Relocator
        </Typography>
        <Box
          style={{
            border: `${theme.palette.grey[500]} 0.1em solid`,
            backgroundColor: theme.palette.grey[100],
            padding: theme.spacing(3),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            style={{
              padding: '1em 0',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <InlineTextField
                style={{ width: '100%' }}
                tabIndex={0}
                autoFocus
                id="SkuRelocateSKU"
                name="sku"
                value={sku}
                onChange={e => setSku(e.target.value)}
                placeholder="Enter SKU"
                label="SKU"
                variant="outlined"
                inputRef={skuInputRef}
                onBlur={getCurrentSkuInventoryDetails}
              />
              <span style={{ padding: '0 1rem' }}>or</span>
              <Button onClick={() => setUpcModalOpen(true)} ordinality="secondary" tabIndex={-1}>
                Search by UPC
              </Button>
              {upcModalOpen && (
                <UpcSearchModal
                  open={upcModalOpen}
                  handleClose={() => setUpcModalOpen(false)}
                  setSku={handleSkuByUpc}
                />
              )}
            </Box>
          </Box>
          <Box
            style={{
              display: 'flex',
              marginTop: '2rem',
              padding: 0,
              alignItems: 'center',
              width: '100%',
            }}
          >
            <InlineTextField
              style={{ width: '100%' }}
              id="SkuRelocateZone"
              name="zoneId"
              value={zoneId || ''}
              onChange={e => setZoneId(parseInt(e.target.value))}
              options={warehouseZones?.map(zone => ({
                label: `${getZoneParentById(warehouseZones, zone.parentWarehouseZoneId)}${
                  zone.name
                }`,
                value: zone.id,
              }))}
              placeholder="Warehouse Zone"
              label="Warehouse Zone"
              variant="outlined"
            />
            <CheckboxWrapper>
              <Checkbox
                checked={warehouseZoneLocked}
                onChange={() => setWarehouseZoneLocked(!warehouseZoneLocked)}
                name="locationLocked"
                tabIndex={-1}
              />
              Lock Warehouse Zone
            </CheckboxWrapper>
          </Box>

          {currentInventoryDetails && (
            <Box
              style={{
                width: '100%',
                margin: 0,
                padding: '0 0 0 1rem',
              }}
            >
              <Typography variant="caption">
                Current Warehouse Zone:{' '}
                {getZoneParentById(
                  warehouseZones,
                  currentInventoryDetails.warehouseBin?.warehouseZone?.parentWarehouseZoneId,
                )}
                {currentInventoryDetails?.warehouseBin?.warehouseZone?.name}
              </Typography>
            </Box>
          )}

          <Box
            style={{
              display: 'flex',
              marginTop: '2rem',
              padding: 0,
              alignItems: 'center',
              width: '100%',
            }}
          >
            <WarehouseBinAutocomplete
              style={{ width: '100%' }}
              id="SKURelocateModalBin"
              selectedBin={bin}
              options={warehouseBinsForZone}
              typeaheadVal={binTypeahead}
              onTypeaheadChange={setBinTypeahead}
              onBinChange={e => {
                setBin((e as unknown) as WarehouseBin);
                skuInputRef.current?.focus();
              }}
            />
            <CheckboxWrapper>
              <Checkbox
                checked={binLocked}
                onChange={() => setBinLocked(!binLocked)}
                tabIndex={-1}
              />
              Lock Bin
            </CheckboxWrapper>
          </Box>
          {currentInventoryDetails && (
            <Box
              style={{
                width: '100%',
                margin: 0,
                padding: '0 0 0 1rem',
              }}
            >
              <Typography variant="caption">
                Current Bin: {currentInventoryDetails?.warehouseBin?.code}
              </Typography>
            </Box>
          )}
          {currentInventoryDetails &&
            (isSnowflakeSku ? (
              <>
                <Box
                  style={{
                    display: 'flex',
                    marginTop: '2rem',
                    padding: 0,
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <InlineTextField
                    style={{ width: '100%' }}
                    id="inspectionRelocateModalInspectionStatusId"
                    disabled={!!!currentInventoryDetails?.inspection}
                    name="InspectionStatusId"
                    value={inspectionStatusId || ''}
                    onChange={e => setInspectionStatusId(e.target.value)}
                    placeholder="Inspection Status"
                    label="Inspection Status"
                    variant="outlined"
                    options={inspectionStatuses?.map((status: any) => ({
                      label: status.name,
                      value: status.id,
                    }))}
                  />
                  <CheckboxWrapper>
                    <Checkbox
                      checked={inspectionStatusLocked}
                      onChange={() => setInspectionStatusLocked(!inspectionStatusLocked)}
                      tabIndex={-1}
                    />
                    Lock Status
                  </CheckboxWrapper>
                </Box>
                <Box
                  style={{
                    width: '100%',
                    margin: 0,
                    padding: '0 0 0 1rem',
                  }}
                >
                  <Typography variant="caption">
                    Current Inspection Status: {currentInventoryDetails?.inspection?.status?.name}
                  </Typography>
                </Box>
              </>
            ) : (
              <Box
                style={{
                  display: 'flex',
                  marginTop: '2rem',
                  padding: 0,
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Alert severity="error">You can only move Snowflake SKUs with this tool</Alert>
              </Box>
            ))}
          <Button
            size="large"
            type="submit"
            ordinality="primary"
            disabled={
              loading || !zoneId || !sku || (!bin && !inspectionStatusId) || !!inventoryHookError
            }
            style={{ width: '100%', marginTop: '1rem' }}
            onClick={e => handleSubmit(e)}
          >
            Relocate
          </Button>
          <ErrorMessage error={inventoryHookError} style={{ width: '100%', marginTop: '1rem' }} />
          {successMessage && (
            <AutoCloseAlert
              open={successAutoCloseAlertOpen}
              handleClose={onSuccessAutoAlertClose}
              severity="success"
              autoHideDuration={8000}
              style={{ width: '100%', marginTop: '1rem' }}
            >
              <AlertTitle>Success</AlertTitle>
              {successMessage}
            </AutoCloseAlert>
          )}
          {errorMessage && (
            <AutoCloseAlert
              open={errorAutoCloseAlertOpen}
              handleClose={onErrorAutoAlertClose}
              autoHideDuration={15000}
              severity="error"
              style={{ width: '100%', marginTop: '1rem' }}
            >
              <AlertTitle>Error</AlertTitle>
              {errorMessage}
            </AutoCloseAlert>
          )}
        </Box>
      </Box>
    </PageWrapper>
  );
};

export default RefurbishedItemRelocator;
