import { GridSortDirection } from '@material-ui/data-grid';
import { Grades, FailRemedies } from '../../pages/service/serviceTicket/_shared/types';
import { SingleItemProcurement } from '../catalog/types';
import { ServicePlanOperator } from '../../pages/service/serviceTicket/ServiceTicketDetailView/ServiceTicketHeader/ServicePlanOperatorsList';

export interface InspectionPoint {
  inspectionPointTypeId: number;
  gradeId: Grades | null;
  failRemedyId: FailRemedies | null;
  note: string | null;
  complete: boolean;
  inspectedAt: string | null; // date string
}
export interface InspectionPointType {
  id: number;
  description: string;
  sortWeight: number;
  passCriteria: string;
  failCriteria: string;
  active: boolean;
}
export interface InspectionPointTypeCategory {
  category: string;
  active: boolean;
  sortWeight: number;
  inspectionPointTypes: InspectionPointType[];
}

export class FEInspectionPoint {
  // Inspection Point
  inspectionPointTypeId: InspectionPoint['inspectionPointTypeId'];
  gradeId: InspectionPoint['gradeId'];
  failRemedyId: InspectionPoint['failRemedyId'];
  note: InspectionPoint['note'];
  complete: InspectionPoint['complete'];
  inspectedAt: InspectionPoint['inspectedAt'];

  // InspectionPointType
  description: InspectionPointType['description'];
  sortWeight: InspectionPointType['sortWeight'];
  passCriteria: InspectionPointType['passCriteria'];
  failCriteria: InspectionPointType['failCriteria'];

  constructor({
    inspectionPointTypeId,
    gradeId,
    failRemedyId,
    note,
    complete,
    inspectedAt,
    description,
    sortWeight,
    passCriteria,
    failCriteria,
  }: InspectionPoint & InspectionPointType) {
    this.inspectionPointTypeId = inspectionPointTypeId;
    this.gradeId = gradeId;
    this.failRemedyId = failRemedyId;
    this.note = note;
    this.complete = complete;
    this.inspectedAt = inspectedAt;
    this.description = description;
    this.sortWeight = sortWeight;
    this.passCriteria = passCriteria;
    this.failCriteria = failCriteria;
  }
}

export interface FEInspectionCategory extends InspectionPointTypeCategory {
  points: FEInspectionPoint[];
}

export interface InspectionPart {
  id: number;
  inspectionPointTypeId: number;
  userId: number;
  sourceId: number;
  sourceBikeSku: string | null;
  sourceOrderNumber: string | null;
  sourceOrderDescription: string | null;
  sourceOrderCost: number | null;
  identifier: string;
  identifierTypeId: number;
  quantity: number;
  statusId: number;
  active: boolean;
}
export interface Inspection {
  id: number;
  sku: string;
  serialNumber: string;
  color: string;
  isReturn: boolean;
  notes: string | null;
  mechanicNotes: string | null;
  mechanicId: number;
  inspectorId: number;
  statusId: number;
  status?: InspectionStatus;
  inspectionPoints: InspectionPoint[];
  inspectionParts: InspectionPart[];
  itemProcurement: SingleItemProcurement;
  created: string; // date string
  intakeNotes: string;
  rejectionNotes: string;
  servicePlanRejectionReasons: ServicePlanRejectionReason[];
  servicePlanOperators: ServicePlanOperator[];
}

// search
export interface InspectionsSearchQueryParams {
  limit: number;
  offset: number;
  orderBy: string;
  orderDirection: GridSortDirection;
  searchQuery: string | undefined;
  inspectorIds: number[];
  mechanicIds: number[];
  statusIds: number[];
}
export interface InspectionSearchResult {
  id: number;
  sku: string;
  serialNumber: string;
  inspectorId: number;
  mechanicId: number;
  created: string;
  statusId: number;
}
export interface InspectionsSearchResponse {
  limit: number;
  offset: number;
  total: number;
  results: InspectionSearchResult[];
}
// end search

export interface InspectionStatus {
  id: number;
  name: string;
  active: boolean;
  statusType: string;
}

// ordered according to workflow
export enum InspectionStatusEnum {
  'Ready for Intake' = 1,
  'Intake Started' = 12,
  'Ready for Inspection' = 13,
  'Inspection Started' = 7,
  'Ready for Parts' = 8,
  'Ready for Line' = 9,
  'Ready for Master Tech' = 11,
  'Service Started' = 2,
  'Service Complete' = 3,
  'Failed' = 6,
  'Rejected' = 10,
  'Ticket Reopened' = 5,

  // deprecated / inactive
  'Service Paused' = 4,
}

export const PAYMENT_READY_SP_STATUSES = [
  InspectionStatusEnum['Ready for Line'],
  InspectionStatusEnum['Ready for Parts'],
  InspectionStatusEnum['Ready for Master Tech'],
];

export interface NetSuiteInventory {
  sku: string;
  wipStateId: number;
  wipState: string;
  binId: number;
  binCode: string;
  locationId: number;
  locationCode: string;
  quantity: number;
}

// todo: return id as number
// update handleRelocate + SkuRelocate wipStateId from string to number
export interface WIPState {
  id: string;
  value: string;
}

export interface Bin {
  id: number;
  locationId: number;
  code: string;
}

export interface Location {
  id: number;
  code: string;
}

export interface User {
  id: number;
  name: string;
  email: string;
  active: true;
}

export interface ServicePlanRejectionReason {
  id?: number;
  name?: string;
}

export enum ServicePlanOperationTypes {
  'Received by' = 0,
  'Mechanic' = 1,
  'Parts added by' = 2,
  'Inspected by' = 3,
  'Intake by' = 4,
}

export const servicePlanOperationLabels: {
  [index in ServicePlanOperationTypes]: { operator: string; operationDate: string };
} = {
  0: { operator: 'Received by', operationDate: 'Received' },
  1: { operator: 'Mechanic', operationDate: 'Mechanic' },
  2: { operator: 'Parts added by', operationDate: 'Parts added' },
  3: { operator: 'Inspected by', operationDate: 'Inspected' },
  4: { operator: 'Intake by', operationDate: 'Intake' },
};
