import { useState, useEffect, useMemo } from 'react';
import debounce from 'lodash/debounce';
import * as inventoryService from '../../../services/inventory/services';
import * as serviceService from '../../../services/service/services';
import {
  InventoryAdjustmentReason,
  WarehouseBin,
  WarehouseZone,
} from '../../../services/inventory/types';
import { InspectionStatus } from '../../../services/service/types';

export const OPERATIONS_LOCATION_ID = 121; // 'LOUISVILLE : OPERATIONS'

export function useInventory({ typeaheadVal, zoneId }: { typeaheadVal?: string; zoneId?: number }) {
  const [warehouseBinsForZone, setWarehouseBinsForZone] = useState<WarehouseBin[]>([]);
  const [warehouseZones, setWarehouseZones] = useState<WarehouseZone[]>();
  const [inspectionStatuses, setInspectionStatuses] = useState<InspectionStatus[]>();
  const [inventoryAdjustmentReasons, setInventoryAdjustmentReasons] = useState<
    InventoryAdjustmentReason[]
  >();
  const [error, setError] = useState<string>();

  // fetch enum data that won't change based on locationId
  useEffect(() => {
    serviceService.getInspectionStatuses({
      onSuccess: res => {
        setInspectionStatuses(res.filter(status => status.active));
      },
      catchFailure: err => setError(err?.message ?? 'Unable to fetch Inspection statuses'),
    });
    inventoryService.getWarehouseZones({
      onSuccess: res => {
        setWarehouseZones(res);
      },
      catchFailure: err => setError(err?.message ?? 'Unable to fetch warehouse zones'),
    });
    inventoryService.getInventoryAdjustmentReasons({
      onSuccess: res => {
        setInventoryAdjustmentReasons(res);
      },
      catchFailure: err => setError(err?.message ?? 'Unable to fetch inventory adjustment reasons'),
    });
  }, []);

  // (re)fetch bin data based on locationId and typeahead
  // only fetches when typeaheadVal is truthy to prevent loading all bins for a location
  const refetchBins = useMemo(
    () =>
      debounce(({ locationId, typeaheadVal, setWarehouseBinsForZone, setError }) => {
        if (typeaheadVal && locationId) {
          inventoryService.getBinsByZone(locationId, typeaheadVal, {
            onSuccess: res => setWarehouseBinsForZone(res),
            catchFailure: err =>
              setError(err?.message ?? 'Unable to fetch bins for selected location'),
          });
        }
      }, 500),
    [],
  );
  useEffect(() => {
    refetchBins({
      locationId: zoneId,
      typeaheadVal,
      setWarehouseBinsForZone,
      setError,
    });
  }, [zoneId, typeaheadVal, refetchBins]);

  return {
    warehouseZones,
    warehouseBinsForZone,
    inspectionStatuses,
    inventoryAdjustmentReasons,
    error,
  };
}
