import React, { FC, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';
import axios from '../../../utils/axios';
import { API_URL } from '../../../constants';
import styles from '../../../styledComponents';
import { TextField, Button, LoaderOverlay } from '../../../components/library';
import { Typography } from '@mui/material';
import { UpcSearchModal } from '../../service/UpcSearchModal';
import { useInventory } from '../../catalog/utils/useInventory';
import { Inventory } from '../../../services/inventory/types';
import { Box } from '@material-ui/core';
import { getZoneNameById } from '../../catalog/utils/inventory/utils';
import { useKeyPress } from '../../../utils/hooks/useKeyPress';

export const INVENTORY_LOCATION_DETAILS_ROUTE = '/inventory/item-details';

const { PageWrapper } = styles;

const InlineTextField = styled(TextField)`
  display: inline-block;
`;

interface SkuRelocatorProps {
  sku?: string;
}

const InventoryLocationDetails: FC<Partial<RouteComponentProps> & SkuRelocatorProps> = ({
  location: windowLocation,
  sku: propSku,
}) => {
  const skuParam = windowLocation ? new URLSearchParams(windowLocation.search).get('sku') : null;
  // Can pass in initial sku as a query param or prop, prefer query param
  const initialSku = skuParam || propSku || '';

  const [loading, setLoading] = useState<boolean>(false);
  const [upcModalOpen, setUpcModalOpen] = useState(false);

  const [sku, setSku] = useState<string>(initialSku);
  const [isSnowflakeSku, setIsSnowflakeSku] = useState<boolean>(false);
  const [currentInventoryDetails, setCurrentInventoryDetails] = useState<Inventory[] | undefined>(
    undefined,
  );
  const { warehouseZones } = useInventory({ typeaheadVal: undefined, zoneId: undefined });

  const [startSearch, setStartSearch] = useState<boolean>(false);
  useKeyPress({ activationKey: 'enter' }, () => setStartSearch(true));
  useEffect(() => {
    const getCurrentSkuInventoryDetails = async () => {
      setLoading(true);
      setCurrentInventoryDetails(undefined);
      if (sku) {
        try {
          await axios.get(`${API_URL}/inventory/skus/${sku}/inventoryRecords`).then(res => {
            const currentInvDetails = res?.data;
            setIsSnowflakeSku(!!currentInvDetails[0].inspection);
            setCurrentInventoryDetails(currentInvDetails);
          });
        } catch (err) {
          console.error(
            err?.response?.data?.message
              ? err.response.data.message
              : err?.message
              ? err?.message
              : 'An unknown error occurred',
          );
        }
      }
      setLoading(false);
      setStartSearch(false);
    };
    if (startSearch && !loading) {
      getCurrentSkuInventoryDetails();
    }
  }, [loading, sku, startSearch]);

  const handleSkuByUpc = (value: string) => {
    setUpcModalOpen(false);
    setSku(value);
    setStartSearch(true);
  };

  const skuInputRef = React.useRef<HTMLInputElement>(null);

  const theme = useTheme();
  return (
    <PageWrapper>
      <Box
        style={{
          width: '100%',
          maxWidth: '500px',
          marginTop: theme.spacing(8),
        }}
      >
        <LoaderOverlay loading={loading} />
        <Typography variant="h4" gutterBottom>
          Inventory Item Lookup
        </Typography>
        <Box
          style={{
            border: `${theme.palette.grey[500]} 0.1em solid`,
            backgroundColor: theme.palette.grey[100],
            padding: theme.spacing(3),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            style={{
              padding: '1em 0',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <InlineTextField
                style={{ width: '100%' }}
                tabIndex={0}
                autoFocus
                id="SkuRelocateSKU"
                name="sku"
                value={sku}
                onChange={e => setSku(e.target.value)}
                placeholder="Enter SKU"
                label="SKU"
                variant="outlined"
                inputRef={skuInputRef}
              />
              <span style={{ padding: '0 1rem' }}>or</span>
              <Button onClick={() => setUpcModalOpen(true)} ordinality="secondary" tabIndex={-1}>
                Search by UPC
              </Button>
              {upcModalOpen && (
                <UpcSearchModal
                  open={upcModalOpen}
                  handleClose={() => setUpcModalOpen(false)}
                  setSku={handleSkuByUpc}
                />
              )}
            </Box>
            <Box style={{ marginTop: '1em' }}>
              <Button
                onClick={() => setStartSearch(true)}
                disabled={!sku}
                ordinality="primary"
                tabIndex={-1}
              >
                Search
              </Button>
            </Box>
          </Box>
          {currentInventoryDetails &&
            currentInventoryDetails.map((inv, index) => (
              <Box
                key={index}
                style={{
                  width: '100%',
                  padding: '1em',
                  border: `${theme.palette.grey[500]} 0.1em solid`,
                  margin: '1em 0',
                }}
              >
                <Typography variant="body1" gutterBottom>
                  {inv.sku}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {inv.quantity} in {inv.warehouseBin?.code} /{' '}
                  {getZoneNameById(warehouseZones, inv.warehouseBin?.warehouseZoneId)}
                </Typography>
                {isSnowflakeSku && (
                  <Typography variant="body1" gutterBottom>
                    Inspection Status: {inv.inspection?.status?.name}
                  </Typography>
                )}
              </Box>
            ))}
        </Box>
      </Box>
    </PageWrapper>
  );
};

export default InventoryLocationDetails;
