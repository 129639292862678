import { WarehouseZone } from '../../../../services/inventory/types';

export const getZoneParentFromZone = (warehouseZone: WarehouseZone) => {
  return warehouseZone.parentWarehouseZone ? `${warehouseZone.parentWarehouseZone.name} > ` : '';
};

export const getZoneNameFromZone = (warehouseZone?: WarehouseZone) => {
  return warehouseZone ? `${getZoneParentFromZone(warehouseZone)}${warehouseZone?.name}` : '';
};

export const getZoneParentById = (warehouseZones?: WarehouseZone[], zoneId?: number | null) => {
  const parent = warehouseZones?.find(zone => zone.id === zoneId)?.name;
  return parent ? `${parent} > ` : '';
};

export const getZoneNameById = (warehouseZones?: WarehouseZone[], zoneId?: number | null) => {
  const zone = warehouseZones?.find(zone => zone.id === zoneId);
  return `${getZoneParentById(warehouseZones, zone?.parentWarehouseZoneId)}${zone?.name}`;
};
