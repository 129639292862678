import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { FormControlLabel, Grid } from '@material-ui/core';
import {
  Modal,
  SmallModalContents,
  ModalHeader,
  ModalBody,
  Button,
  TextField,
  ErrorMessage,
  Checkbox,
  LoaderOverlay,
} from '../../../../components/library';
import { API_URL } from '../../../../constants';
import axios from '../../../../utils/axios';
import { INSPECTION_SEARCH_ROUTE } from '../InspectionSearchView';
import { useInventory } from '../../../catalog/utils/useInventory';
import { WarehouseBinAutocomplete } from '../../../../components/inventory/WarehouseBinAutocomplete';
import { WarehouseBin } from '../../../../services/inventory/types';
import { getOperationsWarehouseZones } from '../RelocateModal/RelocateModal.utils';
import { getZoneParentById } from '../../../catalog/utils/inventory/utils';

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 30px;
`;

const ClickableLabel = styled(FormControlLabel)`
  &.MuiFormControl-root {
    margin-right: 0;
  }
`;

interface CreateInspectionModalProps {
  open: boolean;
  handleClose: () => void;

  sku?: string;
  serialNumber?: string;
}

export const CreateInspectionModalDT: React.FC<CreateInspectionModalProps> = ({
  open,
  handleClose,
}) => {
  const [sku, setSku] = useState<string>();
  const [serialNumber, setSerialNumber] = useState<string>();
  const [zoneId, setZoneId] = useState<number | undefined>(undefined);
  const [binTypeahead, setBinTypeahead] = useState<string>('');
  const [warehouseBin, setWarehouseBin] = useState<WarehouseBin | undefined>(undefined);
  const [isReturnedItem, setIsReturnedItem] = useState<boolean>(false);
  const [isSubIdInsteadOfSku, setIsSubIdInsteadOfSku] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const history = useHistory();

  const { warehouseZones, warehouseBinsForZone, error: relocateInventoryError } = useInventory({
    typeaheadVal: binTypeahead,
    zoneId: zoneId,
  });

  const opsWarehouseZones = getOperationsWarehouseZones(warehouseZones);

  const onSubmit = async () => {
    try {
      setLoading(true);
      setError(undefined);
      // create inspection record
      const inspectionRes = await axios.post(`${API_URL}/service/inspections/create/relocate`, {
        sku,
        serialNumber,
        isReturnedItem,
        toWarehouseZoneId: zoneId,
        toWarehouseBin: warehouseBin?.code,
        overrideSkuValidation: isSubIdInsteadOfSku,
      });
      if (!inspectionRes) {
        throw new Error('An unexpected error occurred.');
      }
      history.push(`${INSPECTION_SEARCH_ROUTE}/${inspectionRes.data.id}`);
    } catch (err) {
      setError(err.response?.data?.message ?? 'An unexpected error occurred.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal open={open} handleClose={handleClose} label={'New Service Plan'}>
        <SmallModalContents>
          <StyledHeader>
            <ModalHeader>New Service Plan</ModalHeader>
            <ClickableLabel
              control={
                <Checkbox
                  checked={isReturnedItem}
                  onChange={() => setIsReturnedItem(!isReturnedItem)}
                />
              }
              label="Returned item"
              labelPlacement="start"
            />
          </StyledHeader>
          <ModalBody>
            <Grid container direction="row" spacing={1} alignItems="center">
              <Grid item xs={8}>
                <TextField
                  id="sku"
                  label={isSubIdInsteadOfSku ? 'Submission ID' : 'SKU'}
                  onChange={e => setSku(e.target.value)}
                  variant="outlined"
                  value={sku}
                />
              </Grid>
              <Grid item xs={4}>
                <ClickableLabel
                  control={
                    <Checkbox
                      checked={isSubIdInsteadOfSku}
                      onChange={() => setIsSubIdInsteadOfSku(!isSubIdInsteadOfSku)}
                      tabIndex={-1}
                    />
                  }
                  label="Use Sub ID"
                  labelPlacement="start"
                />
              </Grid>
            </Grid>
            <TextField
              id="serial-number"
              label="Serial #"
              onChange={e => setSerialNumber(e.target.value)}
              variant="outlined"
              value={serialNumber}
            />
            <TextField
              id="ServiceUpdateWarehouseZoneId"
              name="warehouseZoneId"
              value={zoneId || ''}
              onChange={e => setZoneId(parseInt(e.target.value))}
              options={opsWarehouseZones?.map(zone => ({
                label: `${getZoneParentById(warehouseZones, zone.parentWarehouseZoneId)}${
                  zone.name
                }`,
                value: zone.id,
              }))}
              placeholder="Select Warehouse Zone"
              label="Warehouse Zone"
              variant="outlined"
            />

            <WarehouseBinAutocomplete
              id="CreateInspectionModalBin"
              selectedBin={warehouseBin}
              options={warehouseBinsForZone}
              typeaheadVal={binTypeahead}
              onTypeaheadChange={setBinTypeahead}
              onBinChange={setWarehouseBin}
            />
            <Button
              onClick={onSubmit}
              ordinality="primary"
              size="large"
              disabled={!sku || !serialNumber}
            >
              Create Inspection
            </Button>
            {error && <ErrorMessage error={error} />}
            <ErrorMessage error={relocateInventoryError} />
          </ModalBody>
        </SmallModalContents>
      </Modal>
      <LoaderOverlay loading={loading} />
    </>
  );
};
