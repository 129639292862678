import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormControl, InputLabel, InputAdornment } from '@material-ui/core';
import styled from 'styled-components';
import axios from '../../../../utils/axios';
import { InspectionStatusBadge } from '../InspectionStatusBadge';
import { Button } from '../../../../components/library/Button';
import { TextField } from '../../../../components/library/TextField';
import { MultiSelect, MultiSelectOption } from '../../../../components/library/MultiSelect';
import { CreateInspectionModal } from '../CreateInspectionModal';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SearchIcon from '@material-ui/icons/Search';
import * as actions from '../../../../actions';
import { API_URL, PAGE_SIZE } from '../../../../constants';
import { GlobalState } from '../../../../reducers/types';
import { InspectionStatusOption } from '../_shared/types';
import {
  GridColDef,
  GridPagination,
  GridSortModel,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid';
import { DataGrid } from '../../../../components/library/DataGrid';
import { PageWrapper } from '../../../../styledComponents/wrappers';
import { CreateInspectionModalDT } from '../CreateInspectionModal/CreateInspectionModalDT';

export const INSPECTION_SEARCH_ROUTE = '/service/service-plans';

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing(2)}px`};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => `${theme.spacing(2)}px`};
  flex-wrap: wrap;
  justify-content: flex-end;
`;

const TableMetaWrapper = styled.div`
  ${({ theme }) => `
    display: grid;
    margin-bottom: ${theme.spacing(2)}px;
    padding: ${theme.spacing(2)}px;
    grid-gap: ${theme.spacing(2)}px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      "search search search"
      "filter1 filter2 filter3";

    ${theme.breakpoints.up('md')} {
      grid-template-columns: 1.5fr 1fr 1fr 1fr;
      grid-template-rows: 1fr;
      grid-template-areas:
        "search filter1 filter2 filter3";
    }
  `};
`;

const NewInspectionIcon = styled(AddCircleOutlineIcon)`
  padding-left: ${({ theme }) => `${theme.spacing(1)}px`};
`;

function CustomPagination() {
  return (
    <GridToolbarContainer sx={{ justifyContent: 'space-between' }}>
      <GridToolbarExport />
      <GridPagination sx />
    </GridToolbarContainer>
  );
}

export const InspectionSearchView: React.FC<{ openNewInspectionModalOnLoad?: boolean }> = ({
  openNewInspectionModalOnLoad = false,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  // inputs
  const {
    params: { paginationModel, sortModel, searchQuery, inspectorIds, mechanicIds, statusIds },
    results,
    total,
    loading,
  } = useSelector((state: GlobalState) => state.inspectionsSearch);

  // data
  const [inspectorLookup, setInspectorLookup] = useState<MultiSelectOption[]>([]);
  const [mechanicLookup, setMechanicLookup] = useState<MultiSelectOption[]>([]);
  const [statusLookup, setStatusLookup] = useState<InspectionStatusOption[]>([]);

  // table / view state
  const [isNewModalOpen, setIsNewModalOpen] = useState<boolean>(openNewInspectionModalOnLoad);

  // todo: Remove Once Drivetrain Inventory is Live
  const [useDTModal, setUseDTModal] = useState(false);
  useEffect(() => {
    const fetchFeatureToggle = async () => {
      const url = `${API_URL}/user/featureToggles/drivetrainInventoryManagment`;
      try {
        const response = await axios.get(url);
        if (response) {
          setUseDTModal(response?.data);
        } else {
          setUseDTModal(false);
        }
      } catch (error) {
        setUseDTModal(false);
      }
    };

    fetchFeatureToggle();
  }, []);

  useEffect(() => {
    Promise.all([
      axios.get(`${API_URL}/service/inspectionStatuses`),
      axios.get(`${API_URL}/service/mechanics`),
      axios.get(`${API_URL}/service/inspectors`),
    ])
      .then(([statusesRes, mechanicsRes, inspectorsRes]) => {
        if (!statusesRes || !mechanicsRes || !inspectorsRes) {
          dispatch(actions.setInspectionsSearchError('Could not load filter data'));
        } else {
          setStatusLookup(
            statusesRes.data.map((statusOption: InspectionStatusOption) => {
              return statusOption.active
                ? statusOption
                : {
                    id: statusOption.id,
                    name: `${statusOption.name} (Deprecated)`,
                  };
            }),
          );
          setMechanicLookup(mechanicsRes.data);
          setInspectorLookup(inspectorsRes.data);
        }
      })
      .catch(err => {
        console.error(err);
        dispatch(actions.setInspectionsSearchError(err.message));
      });

    dispatch(actions.searchInspections());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: GridColDef[] = [
    { field: 'sku', headerName: 'SKU', flex: 1, disableColumnMenu: true },
    { field: 'serialNumber', headerName: 'SERIAL #', flex: 1, disableColumnMenu: true },
    {
      field: 'inspectorId',
      headerName: 'SERVICE WRITER',
      flex: 1,
      disableColumnMenu: true,
      valueGetter: params => {
        const inspectorId = params.value as number;
        const inspectorName = inspectorLookup.find(i => i.id === inspectorId)?.name;
        return inspectorName ?? 'N/A';
      },
    },
    {
      field: 'mechanicId',
      headerName: 'MECHANIC',
      flex: 1,
      disableColumnMenu: true,
      valueGetter: params => {
        const mechanicId = params.value as number;
        const mechanicName = mechanicLookup.find(i => i.id === mechanicId)?.name;
        return mechanicName ?? 'N/A';
      },
    },
    {
      field: 'created',
      headerName: 'CREATED ON',
      flex: 1,
      disableColumnMenu: true,
      valueGetter: params => {
        const createdString = params.value as string;
        return new Date(createdString).toLocaleString('en-US', {
          timeZone: 'America/Denver',
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        });
      },
    },
    {
      field: 'statusId',
      headerName: 'STATUS',
      sortable: false,
      flex: 1,
      disableColumnMenu: true,
      valueGetter: params => {
        // used for export, not for display
        const statusId = params.value as number;
        const statusName = statusLookup.find(s => s.id === statusId)?.name;
        return statusName ?? 'N/A';
      },
      renderCell: params => {
        const statusId = params.row.statusId as number;
        const statusName = statusLookup.find(s => s.id === statusId)?.name;
        return (
          <InspectionStatusBadge statusId={params.row.statusId as number} label={statusName} />
        );
      },
    },
  ];

  const hasFiltersToClear =
    searchQuery !== '' || !!inspectorIds.length || !!mechanicIds.length || !!statusIds.length;

  return (
    <PageWrapper style={{ overflow: 'hidden' }}>
      <HeaderWrapper>
        <h1>Service Plans</h1>
        <ButtonsWrapper>
          {hasFiltersToClear && (
            <Button
              onClick={() => dispatch(actions.clearInspectionsSearchParams())}
              ordinality="secondary"
            >
              Clear Filters
            </Button>
          )}
          <Button onClick={() => setIsNewModalOpen(true)} ordinality="primary">
            <>
              New Service Plan
              <NewInspectionIcon />
            </>
          </Button>

          {isNewModalOpen &&
            (useDTModal ? (
              <CreateInspectionModalDT
                open={isNewModalOpen}
                handleClose={() => setIsNewModalOpen(false)}
              />
            ) : (
              <CreateInspectionModal
                open={isNewModalOpen}
                handleClose={() => setIsNewModalOpen(false)}
              />
            ))}
        </ButtonsWrapper>
      </HeaderWrapper>
      <TableMetaWrapper>
        <TextField
          id="inspectionSearchQueryField"
          value={searchQuery}
          onChange={e => dispatch(actions.setInspectionsSearchParam('searchQuery', e.target.value))}
          placeholder="Search by SKU, serial #, or part NS PO #"
          autoFocus
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          style={{ gridArea: 'search' }}
        />

        {/* filters */}
        <FormControl variant="outlined" style={{ gridArea: 'filter1' }}>
          <InputLabel id="inspectorsLabel">Service Writer</InputLabel>
          <MultiSelect
            label="Service Writer"
            labelId="inspectorsLabel"
            id="inspectors"
            selectedOptions={inspectorIds}
            onChange={e =>
              dispatch(
                actions.setInspectionsSearchParam('inspectorIds', e.target.value as number[]),
              )
            }
            options={inspectorLookup}
          />
        </FormControl>
        <FormControl variant="outlined" style={{ gridArea: 'filter2' }}>
          <InputLabel htmlFor="mechanics">Mechanic</InputLabel>
          <MultiSelect
            label="Mechanic"
            labelId="mechanicsLabel"
            id="mechanics"
            key="inspectors"
            selectedOptions={mechanicIds}
            onChange={e =>
              dispatch(actions.setInspectionsSearchParam('mechanicIds', e.target.value as number[]))
            }
            options={mechanicLookup}
          />
        </FormControl>
        <FormControl variant="outlined" style={{ gridArea: 'filter3' }}>
          <InputLabel htmlFor="statuses">Status</InputLabel>
          <MultiSelect
            label="Status"
            labelId="statusesLabel"
            id="statuses"
            key="inspectors"
            selectedOptions={statusIds}
            onChange={e =>
              dispatch(actions.setInspectionsSearchParam('statusIds', e.target.value as number[]))
            }
            options={statusLookup}
          />
        </FormControl>
      </TableMetaWrapper>
      <DataGrid
        height="40rem"
        width="100%"
        columns={columns}
        rows={results}
        rowCount={total}
        loading={loading}
        paginationModel={paginationModel}
        onPaginationModelChange={paginationModel => {
          dispatch(actions.setInspectionsSearchParam('paginationModel', paginationModel));
        }}
        paginationMode="server"
        pagination
        sortingMode="server"
        pageSizeOptions={[PAGE_SIZE, PAGE_SIZE * 2, PAGE_SIZE * 3, PAGE_SIZE * 4]}
        sortModel={sortModel}
        onSortModelChange={(model: GridSortModel) =>
          dispatch(actions.setInspectionsSearchParam('sortModel', model))
        }
        onRowClick={params => history.push(`/service/service-plans/${params.id}`)}
        slots={{
          footer: CustomPagination,
        }}
      />
    </PageWrapper>
  );
};
