import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import '../styles/home.css';
import tradeupLogo from '../images/trade-up.svg';
import adminupLogo from '../images/adminup.png';

import { INSPECTION_SEARCH_ROUTE } from './service/serviceTicket/InspectionSearchView';
import { TRADEUP_SEARCH_ROUTE } from './tradeups/SubmissionListView';
import { REFURBISHED_ITEM_RELOCATOR_ROUTE } from './inventory/relocate/ReburbishedItemRelocator';
import { MULTI_ITEM_RELOCATOR_ROUTE } from './inventory/relocate/MultiItemRelocator';

const Home = () => {
  return (
    <>
      <div className="home-wrap">
        <Link className="home-link" to="/products/360view">
          <section className="section-wrap">
            <svg
              width="116"
              height="116"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-circle"
            >
              <circle cx="12" cy="12" r="10" />
            </svg>
            <h4>360 View</h4>
            <span>View lifespan and activity of products</span>
          </section>
        </Link>
        <Link className="home-link" to="/catalog/products">
          <section className="section-wrap">
            <PageviewOutlinedIcon style={{ height: '116px', width: '116px' }} />
            <h4>Catalog</h4>
            <span>Search Product Database</span>
          </section>
        </Link>
        <Link className="home-link" to="/catalog/upc">
          <section className="section-wrap">
            <ConfirmationNumberOutlinedIcon style={{ height: '116px', width: '116px' }} />
            <h4>UPC Search</h4>
            <span>Search + Perform tasks via UPC</span>
          </section>
        </Link>
        <Link className="home-link" to={REFURBISHED_ITEM_RELOCATOR_ROUTE}>
          <section className="section-wrap">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="116"
              height="116"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-arrow-right"
            >
              <line x1="5" y1="12" x2="19" y2="12" />
              <polyline points="12 5 19 12 12 19" />
            </svg>
            <h4>Relocate</h4>
            <span>Relocate a refurbished item</span>
          </section>
        </Link>
        <Link className="home-link" to={MULTI_ITEM_RELOCATOR_ROUTE}>
          <section className="section-wrap">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="116"
              height="116"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-move"
            >
              <polyline points="5 9 2 12 5 15"></polyline>
              <polyline points="9 5 12 2 15 5"></polyline>
              <polyline points="15 19 12 22 9 19"></polyline>
              <polyline points="19 9 22 12 19 15"></polyline>
              <line x1="2" y1="12" x2="22" y2="12"></line>
              <line x1="12" y1="2" x2="12" y2="22"></line>
            </svg>
            <h4>Multi Relocate</h4>
            <span>Relocate multiple SKUs</span>
          </section>
        </Link>
        <Link className="home-link" to={INSPECTION_SEARCH_ROUTE}>
          <section className="section-wrap">
            <svg
              width="116"
              height="116"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-eye"
            >
              <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
              <circle cx="12" cy="12" r="3" />
            </svg>
            <h4>Service Plans</h4>
            <span>Service, grade and log bikes</span>
          </section>
        </Link>
        <Link className="home-link" to="/catalog/product-creation">
          <section className="section-wrap">
            <svg
              width="116"
              height="116"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-plus-square"
            >
              <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
              <line x1="12" y1="8" x2="12" y2="16" />
              <line x1="8" y1="12" x2="16" y2="12" />
            </svg>
            <h4>Add Product</h4>
            <span>View, add and SKU new products</span>
          </section>
        </Link>
        <Link className="home-link" to="/marketing/updateBlogs">
          <section className="section-wrap">
            <svg
              width="116"
              height="116"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-file-text"
            >
              <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" y1="13" x2="8" y2="13" />
              <line x1="16" y1="17" x2="8" y2="17" />
              <polyline points="10 9 9 9 8 9" />
            </svg>
            <h4>Blogs</h4>
            <span>Update where blogs display</span>
          </section>
        </Link>
        <Link className="home-link" to={TRADEUP_SEARCH_ROUTE}>
          <section className="section-wrap">
            <img src={tradeupLogo} alt="logo" />
            <h4>Trade-Ups</h4>
            <span>One stop shop for all your tradeups</span>
          </section>
        </Link>
        {/* TODO - Create a new version of this page for Inventory in Drivetrain
        <Link className="home-link" to="/netsuite/locateInventory">
          <section className="section-wrap">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="116"
              height="116"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-archive"
            >
              <polyline points="21 8 21 21 3 21 3 8"></polyline>
              <rect x="1" y="3" width="22" height="5"></rect>
              <line x1="10" y1="12" x2="14" y2="12"></line>
            </svg>
            <h4>Inventory Locator</h4>
            <span>Locate Inventory</span>
          </section>
        </Link> 
        */}
        <Link className="home-link" to="https://theproscloset.atlassian.net/wiki/x/HwCQR">
          <section className="section-wrap">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="116"
              height="116"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-book-open"
            >
              <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z" />
              <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z" />
            </svg>{' '}
            <h4>SOPs</h4>
            <span>View & add SOPS for all departments</span>
          </section>
        </Link>
        <Link className="home-link" to="/account/home">
          <section className="section-wrap">
            <svg
              width="116"
              height="116"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-lock"
            >
              <rect x="3" y="11" width="18" height="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
            <h4>Account</h4>
            <span>Change your password</span>
          </section>
        </Link>
        {/* TODO - Create a new version of this page for Inventory in Drivetrain
        <Link className="home-link" to="/inventoryAdjustments">
          <section className="section-wrap">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="116"
              height="116"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-list"
            >
              <line x1="8" y1="6" x2="21" y2="6" />
              <line x1="8" y1="12" x2="21" y2="12" />
              <line x1="8" y1="18" x2="21" y2="18" />
              <line x1="3" y1="6" x2="3.01" y2="6" />
              <line x1="3" y1="12" x2="3.01" y2="12" />
              <line x1="3" y1="18" x2="3.01" y2="18" />
            </svg>
            <h4>Inventory Adjustments</h4>
            <span>Adjust Netsuite Inventory</span>
          </section>
        </Link> 
        */}
        <Link className="home-link" to="/buyers/tradeups/admin">
          <section className="section-wrap">
            <img src={adminupLogo} alt="logo" />
            <h4>TRADE-UPS ADMIN</h4>
            <span>Trade Ups admin controls</span>
          </section>
        </Link>
      </div>
    </>
  );
};

export default Home;
