import React, { FC, useCallback, useEffect, useState } from 'react';
import axios from '../../../utils/axios';
import MaterialTable from '@material-table/core';

import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { Button, Checkbox, LoaderOverlay, TextField } from '../../../components/library';
import styledComponents from '../../../styledComponents';
import { useInventory } from '../../catalog/utils/useInventory';
import { UpcSearchModal } from '../../service/UpcSearchModal';
import { Inventory, WarehouseBin } from '../../../services/inventory/types';
import { API_URL } from '../../../constants';
import { WarehouseBinAutocomplete } from '../../../components/inventory/WarehouseBinAutocomplete';
import { AutoCloseAlert, useAutoCloseAlert } from '../../../components/library/AutoCloseAlert';
import { AlertTitle } from '@mui/material';
import { getZoneNameById, getZoneParentById } from '../../catalog/utils/inventory/utils';

export const MULTI_ITEM_RELOCATOR_ROUTE = '/inventory/relocate/multi-item';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      flexDirection: 'row',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    inputContainer: {
      width: '38%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      display: 'flex',
      flexDirection: 'column',
    },
    summaryContainer: {
      marginTop: theme.spacing(3),
      width: '60%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },

    submissionLookup: {
      borderTop: `6px solid ${theme.palette.primary.main}`,
    },
    submissionLink: {
      color: theme.palette.text.secondary,
    },
  }),
);

const { PageWrapper } = styledComponents;

const InlineTextField = styled(TextField)`
  display: inline-block;
`;

const CheckboxWrapper = styled.label`
  display: inline-block;
  cursor: pointer;
`;

interface RelocationRow {
  id: number;
  currentItem: Inventory;
  toWarehouseBin: WarehouseBin;
  transferQuantity: number;
}

const MultiItemRelocator: FC<Partial<RouteComponentProps>> = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [skuLoading, setSkuLoading] = useState<boolean>(false);

  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
  const {
    autoCloseAlertOpen: successAutoCloseAlertOpen,
    handleAutoCloseAlertOpen: handleSuccessAutoCloseAlertOpen,
    handleAutoCloseAlertClose: handleSuccessAutoCloseAlertClose,
  } = useAutoCloseAlert();
  const onSuccessAutoAlertClose = useCallback(() => {
    setSuccessMessage(undefined);
    handleSuccessAutoCloseAlertClose();
  }, [setSuccessMessage, handleSuccessAutoCloseAlertClose]);

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const {
    autoCloseAlertOpen: errorAutoCloseAlertOpen,
    handleAutoCloseAlertOpen: handleErrorAutoCloseAlertOpen,
    handleAutoCloseAlertClose: handleErrorAutoCloseAlertClose,
  } = useAutoCloseAlert();
  const onErrorAutoAlertClose = useCallback(() => {
    setSuccessMessage(undefined);
    handleErrorAutoCloseAlertClose();
  }, [setSuccessMessage, handleErrorAutoCloseAlertClose]);

  const [fromZoneId, setFromZoneId] = useState<number | undefined>(undefined);
  const [toZoneId, setToZoneId] = useState<number | undefined>(undefined);
  const [disableWarehouseZoneChange, setDisableWarehouseZoneChange] = useState<boolean>(false);

  const [fromBinLocationOptions, setFromBinLocationOptions] = useState<WarehouseBin[]>([]);
  const [binTypeahead, setBinTypeahead] = useState<string>('');
  const [addItemToBinLocked, setAddItemToBinLocked] = useState<boolean>(false);
  const [addItemFromBinLocked, setAddItemFromBinLocked] = useState<boolean>(false);
  const [itemAddQuantityLocked, setItemAddQuantityLocked] = useState<boolean>(false);

  // Details on the item being added to the list
  const [addItemSku, setAddItemSku] = useState<string>('');
  const [itemIsInFromBin, setItemIsInFromBin] = useState<boolean>(false);
  const [addItemToBin, setAddItemToBin] = useState<WarehouseBin | undefined>(undefined);
  const [addItemFromBin, setAddItemFromBin] = useState<WarehouseBin | undefined>(undefined);
  const [addItemQuantity, setAddItemQuantity] = useState<number | undefined>(undefined);
  const [addItemCurrentDetails, setItemCurrentDetails] = useState<Inventory[] | undefined>(
    undefined,
  );

  const [itemAddError, setItemAddError] = useState<string | undefined>(undefined);
  const {
    autoCloseAlertOpen: itemErrorAutoCloseAlertOpen,
    handleAutoCloseAlertOpen: handleItemErrorAutoCloseAlertOpen,
    handleAutoCloseAlertClose: handleItemErrorAutoCloseAlertClose,
  } = useAutoCloseAlert();

  const onItemErrorAutoAlertClose = useCallback(() => {
    setItemAddError(undefined);
    handleItemErrorAutoCloseAlertClose();
  }, [setItemAddError, handleItemErrorAutoCloseAlertClose]);

  const [upcModalOpen, setUpcModalOpen] = useState(false);
  const bob = false;
  if (bob) {
    setBinTypeahead('a');
    setUpcModalOpen(false);
    setLoading(false);
  }

  const [relocationRows, setRelocationRows] = useState<RelocationRow[]>([]);

  const { warehouseZones, warehouseBinsForZone, error: relocateHookError } = useInventory({
    typeaheadVal: binTypeahead,
    zoneId: toZoneId,
  });

  const handleSkuByUpc = (value: string) => {
    setUpcModalOpen(false);
    setAddItemSku(value);
  };
  const skuInputRef = React.useRef<HTMLInputElement>(null);

  const getCurrentSkuInventoryDetails = async () => {
    setSkuLoading(true);
    if (addItemSku) {
      try {
        await axios.get(`${API_URL}/inventory/skus/${addItemSku}/inventoryRecords`).then(res => {
          const currentInvDetails = res?.data;
          const currentItem = currentInvDetails.find(
            (inv: Inventory) =>
              inv.warehouseBin?.warehouseZoneId === fromZoneId && inv.quantity > 0,
          );
          // Validate the one of the entries is for the fromZoneId
          if (!currentItem) {
            const allZones = currentInvDetails.map(
              (inv: Inventory) =>
                `${getZoneParentById(
                  warehouseZones,
                  inv.warehouseBin?.warehouseZone?.parentWarehouseZoneId,
                )}${inv.warehouseBin?.warehouseZone?.name}`,
            );
            setItemAddError(
              `SKU not found in warehouse zone ${getZoneNameById(warehouseZones, fromZoneId)} ${
                allZones.length > 0
                  ? `. Current in zones ::\n ${allZones.join(',\n ')}`
                  : ', or any other zones'
              }`,
            );
            handleItemErrorAutoCloseAlertOpen();
            return;
          }
          const binLocations = currentInvDetails
            .filter((inv: Inventory) => inv.quantity > 0)
            .map((inv: Inventory) => inv.warehouseBin);
          setFromBinLocationOptions(binLocations);
          setItemAddError(undefined);
          handleItemErrorAutoCloseAlertClose();
          setItemCurrentDetails(currentInvDetails);
          setSkuLoading(false);
        });
      } catch (err) {
        const errMsg = err?.response?.data?.message
          ? err.response.data.message
          : err?.message
          ? err?.message
          : 'An unknown error occurred';
        setItemAddError(`[Error] ${errMsg}`);
        handleItemErrorAutoCloseAlertOpen();
      }
    }
  };

  useEffect(() => {
    if (skuLoading && addItemFromBin) {
      const itemInCurrentBin = addItemCurrentDetails?.find(
        (inv: Inventory) => inv.warehouseBin?.id === addItemFromBin?.id,
      );
      if (!itemInCurrentBin) {
        const allZones = addItemCurrentDetails?.map(
          (inv: Inventory) =>
            `${getZoneParentById(
              warehouseZones,
              inv.warehouseBin?.warehouseZone?.parentWarehouseZoneId,
            )}::${inv.warehouseBin?.code}`,
        );
        setItemAddError(
          `SKU not found in warehouse zone ${getZoneNameById(warehouseZones, fromZoneId)} ${
            allZones && allZones.length > 0
              ? `. Current in zones ::\n ${allZones.join(',\n ')}`
              : ', or any other zones'
          }`,
        );
        setSkuLoading(false);
        handleItemErrorAutoCloseAlertOpen();
        setItemIsInFromBin(false);
      } else {
        setItemIsInFromBin(true);
      }
    }
  }, [
    addItemCurrentDetails,
    addItemFromBin,
    addItemSku,
    fromZoneId,
    handleItemErrorAutoCloseAlertOpen,
    itemIsInFromBin,
    skuLoading,
    warehouseZones,
  ]);

  const deleteItem = async (xferObj: RelocationRow): Promise<void> => {
    const { id } = xferObj;
    const xferArr = relocationRows;
    const newArr = xferArr
      .filter((x: any) => x.id !== id)
      .map((x: any, idx: number) => ({ ...x, id: idx }));
    setRelocationRows(newArr);
    return Promise.resolve();
  };

  const validateAddItem = () => {
    if (!itemIsInFromBin) {
      return `Item ${addItemSku} is not in bin ${addItemFromBin?.code}`;
    }
    if (!addItemCurrentDetails) {
      setItemAddError('SKU not found in inventory');
      handleItemErrorAutoCloseAlertOpen();
      return;
    }
    const itemInCurrentBin = addItemCurrentDetails.find(
      inv => inv.warehouseBin?.id === addItemFromBin?.id,
    );
    if (!itemInCurrentBin) {
      return `Item ${addItemSku} is not in bin ${addItemFromBin?.code}`;
    }

    if (!addItemSku || !addItemToBin || !addItemQuantity) {
      return 'Please fill out all fields';
    }
    // Validate it is not already in the list
    if (
      relocationRows.find(
        row => row.currentItem.sku === addItemSku && row.toWarehouseBin.id === addItemToBin.id,
      )
    ) {
      return `Item ${addItemSku} going to bin ${addItemToBin.code} is already in the list`;
    }

    // Validate the quantity is not greater than the current quantity
    const itemQuantityInList = relocationRows.reduce((acc, row) => {
      return row.currentItem.sku === addItemSku &&
        row.currentItem.warehouseBinId === addItemFromBin?.id
        ? acc + row.transferQuantity
        : acc;
    }, 0);

    if (addItemQuantity + itemQuantityInList > itemInCurrentBin.quantity) {
      return `You are trying to relocate ${addItemQuantity} of sku ${addItemSku} which has ${
        itemInCurrentBin.quantity
      } in bin ${itemInCurrentBin.warehouseBin?.code}. ${
        itemQuantityInList ? `You have already relocated ${itemQuantityInList}` : ''
      }`;
    }
    // Validate the bin is in the toZoneId
    if (addItemToBin.warehouseZoneId !== toZoneId) {
      return `Bin ${addItemToBin.code} is not in the to location ${getZoneNameById(
        warehouseZones,
        toZoneId,
      )}`;
    }
    return undefined;
  };

  const clearPageState = () => {
    setRelocationRows([]);
    setFromZoneId(undefined);
    setToZoneId(undefined);
    setAddItemSku('');
    setAddItemToBin(undefined);
    setAddItemQuantity(undefined);
    setItemCurrentDetails(undefined);
    handleErrorAutoCloseAlertOpen();
  };

  const setFromZoneData = (zoneId: string) => {
    if (parseInt(zoneId, 10) === fromZoneId) {
      return;
    }
    setFromZoneId(parseInt(zoneId));
    setAddItemFromBin(undefined);
  };

  const clearItemForm = () => {
    setAddItemSku('');
    if (!addItemToBinLocked) {
      setAddItemToBin(undefined);
    }
    if (!addItemFromBinLocked) {
      setAddItemFromBin(undefined);
    }
    if (!itemAddQuantityLocked) {
      setAddItemQuantity(parseInt(''));
    }

    setItemCurrentDetails(undefined);
  };

  const addItem = () => {
    const errorMessage = validateAddItem();
    if (errorMessage) {
      setItemAddError(errorMessage);
      handleItemErrorAutoCloseAlertOpen();
      return;
    }

    const itemInCurrentBin = addItemCurrentDetails?.find(
      inv => inv.warehouseBin?.id === addItemFromBin?.id,
    );

    const newItems: RelocationRow[] = [
      ...relocationRows,
      {
        id: relocationRows.length,
        currentItem: itemInCurrentBin as Inventory,
        toWarehouseBin: addItemToBin as WarehouseBin,
        transferQuantity: addItemQuantity as number,
      },
    ];
    setRelocationRows(newItems as RelocationRow[]);
    clearItemForm();
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setLoading(true);
    // Logic to relocate items
    try {
      const relocationItems = relocationRows.map(row => ({
        sku: row.currentItem.sku,
        fromWarehouseBinId: row.currentItem.warehouseBin?.id,
        toWarehouseBinId: row.toWarehouseBin.id,
        quantity: row.transferQuantity,
      }));
      const res = await axios.post(`${API_URL}/inventory/skus/relocate`, {
        fromZoneId,
        toZoneId,
        relocationItems,
      });
      // What is a good response with detail to show what was relocated?
      console.log('relocateMultipleItemSkus response', res);
      setSuccessMessage(
        `Successfully relocated items ${relocationItems
          .map(
            item =>
              `${item.sku} (${item.quantity}) from ${item.fromWarehouseBinId} to ${item.toWarehouseBinId}`,
          )
          .join(', ')}`,
      );
      handleSuccessAutoCloseAlertOpen();
      clearPageState();
    } catch (err) {
      const error = err?.response?.data?.message
        ? err.response.data.message
        : err?.message
        ? err?.message
        : 'An unknown error occurred';
      console.error(error);
      setErrorMessage(error);
      handleErrorAutoCloseAlertOpen();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (relocationRows.length > 0) {
      setDisableWarehouseZoneChange(true);
    } else {
      setDisableWarehouseZoneChange(false);
    }
  }, [relocationRows.length]);

  const theme = useTheme();
  return (
    <PageWrapper style={{ overflow: 'hidden' }}>
      <LoaderOverlay loading={loading} />
      <Box
        style={{
          width: '100%',
          maxWidth: '1500px',
          marginTop: theme.spacing(8),
        }}
      >
        <LoaderOverlay loading={loading} />
        <Typography variant="h4" gutterBottom>
          Multi Item Relocator
        </Typography>
        <Box
          className={classes.formContainer}
          style={{
            width: '100%',
            marginTop: theme.spacing(3),
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box className={classes.inputContainer}>
            <Box
              style={{
                marginTop: theme.spacing(3),
                padding: theme.spacing(3),
                border: `5px solid ${theme.palette.divider}`,
              }}
            >
              <Box>
                <InlineTextField
                  id="fromWarehouseZone"
                  name="fromWarehouseZoneId"
                  value={fromZoneId || ''}
                  disabled={disableWarehouseZoneChange}
                  onChange={e => setFromZoneData(e.target.value)}
                  options={warehouseZones?.map(zone => ({
                    label: `${getZoneParentById(warehouseZones, zone.parentWarehouseZoneId)}${
                      zone.name
                    }`,
                    value: zone.id,
                  }))}
                  placeholder="From Warehouse Zone"
                  label="From Warehouse Zone"
                  variant="outlined"
                />
              </Box>

              <Box style={{ marginTop: theme.spacing(3) }}>
                <InlineTextField
                  id="toWarehouseZone"
                  name="toWarehouseZoneId"
                  value={toZoneId || ''}
                  disabled={disableWarehouseZoneChange}
                  onChange={e => setToZoneId(parseInt(e.target.value))}
                  options={warehouseZones?.map(zone => ({
                    label: `${getZoneParentById(warehouseZones, zone.parentWarehouseZoneId)}${
                      zone.name
                    }`,
                    value: zone.id,
                  }))}
                  placeholder="To Warehouse Zone"
                  label="To Warehouse Zone"
                  variant="outlined"
                />
              </Box>
              {disableWarehouseZoneChange && (
                <Box style={{ marginTop: theme.spacing(3) }}>
                  Warehouse Zones are locked because items have been added to the list.
                </Box>
              )}
            </Box>
            {toZoneId && fromZoneId && (
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  marginTop: theme.spacing(3),
                  padding: theme.spacing(3),
                  border: `5px solid ${theme.palette.divider}`,
                }}
              >
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <InlineTextField
                    tabIndex={0}
                    autoFocus
                    id="SkuRelocateSKU"
                    name="sku"
                    value={addItemSku}
                    onChange={e => setAddItemSku(e.target.value)}
                    placeholder="Enter SKU"
                    label="SKU"
                    variant="outlined"
                    inputRef={skuInputRef}
                    onBlur={getCurrentSkuInventoryDetails}
                  />
                  <span style={{ padding: '0 1rem' }}>or</span>
                  <Button
                    onClick={() => setUpcModalOpen(true)}
                    ordinality="secondary"
                    tabIndex={-1}
                  >
                    Search by UPC
                  </Button>
                  {upcModalOpen && (
                    <UpcSearchModal
                      open={upcModalOpen}
                      handleClose={() => setUpcModalOpen(false)}
                      setSku={handleSkuByUpc}
                    />
                  )}
                </Box>
                <Box
                  style={{
                    marginTop: theme.spacing(3),
                    gap: theme.spacing(2),
                    display: 'flex',
                    alignItems: 'flex-start',
                    width: '100%',
                  }}
                >
                  <Box style={{ width: '50%' }}>
                    <WarehouseBinAutocomplete
                      style={{ width: '100%' }}
                      id="SKURelocateModalBin"
                      selectedBin={addItemFromBin}
                      label="From Bin"
                      placeholder='Enter "From" Bin'
                      options={fromBinLocationOptions.filter(
                        bin => bin.warehouseZoneId === fromZoneId,
                      )}
                      typeaheadVal={binTypeahead}
                      onTypeaheadChange={setBinTypeahead}
                      onBinChange={e => {
                        setAddItemFromBin((e as unknown) as WarehouseBin);
                        skuInputRef.current?.focus();
                      }}
                    />{' '}
                    <CheckboxWrapper>
                      <Checkbox
                        checked={addItemFromBinLocked}
                        onChange={() => setAddItemFromBinLocked(!addItemFromBinLocked)}
                        tabIndex={-1}
                      />
                      Lock From Bin
                    </CheckboxWrapper>
                  </Box>
                  <Box
                    style={{
                      width: '50%',
                      display: 'flex',
                      alignItems: 'flex-end',
                      flexDirection: 'column',
                    }}
                  >
                    <WarehouseBinAutocomplete
                      style={{ width: '100%' }}
                      id="SKURelocateModalBin"
                      selectedBin={addItemToBin}
                      label="To Bin"
                      placeholder='Enter "To" Bin'
                      options={warehouseBinsForZone}
                      typeaheadVal={binTypeahead}
                      onTypeaheadChange={setBinTypeahead}
                      onBinChange={e => {
                        setAddItemToBin((e as unknown) as WarehouseBin);
                        skuInputRef.current?.focus();
                      }}
                    />
                    <CheckboxWrapper>
                      <Checkbox
                        checked={addItemToBinLocked}
                        onChange={() => setAddItemToBinLocked(!addItemToBinLocked)}
                        tabIndex={-1}
                      />
                      Lock To Bin
                    </CheckboxWrapper>
                  </Box>
                </Box>
                <Box
                  style={{
                    marginTop: theme.spacing(1),
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    justifyContent: 'space-between',
                    width: '45%',
                  }}
                >
                  <InlineTextField
                    id="QuantityToRelocate"
                    name="quantity"
                    type="number"
                    value={addItemQuantity}
                    onChange={e => setAddItemQuantity(parseInt(e.target.value, 10))}
                    placeholder="Enter Quantity"
                    label="Quantity"
                    variant="outlined"
                  />
                  <CheckboxWrapper>
                    <Checkbox
                      checked={itemAddQuantityLocked}
                      onChange={() => setItemAddQuantityLocked(!itemAddQuantityLocked)}
                      tabIndex={-1}
                    />
                    Lock Quanitity
                  </CheckboxWrapper>
                </Box>
                <Box style={{ marginTop: theme.spacing(1) }}>
                  <Button
                    size="large"
                    type="submit"
                    ordinality="primary"
                    disabled={
                      loading ||
                      skuLoading ||
                      !addItemSku ||
                      !itemIsInFromBin ||
                      !addItemFromBin ||
                      !addItemToBin ||
                      !addItemQuantity ||
                      !!relocateHookError
                    }
                    style={{ width: '100%', marginTop: '1rem' }}
                    onClick={addItem}
                  >
                    Add Item
                  </Button>
                </Box>
                {itemAddError && (
                  <AutoCloseAlert
                    open={itemErrorAutoCloseAlertOpen}
                    handleClose={onItemErrorAutoAlertClose}
                    autoHideDuration={15000}
                    severity="error"
                    style={{ width: '100%', marginTop: '1rem' }}
                  >
                    <AlertTitle>Error</AlertTitle>
                    <Typography style={{ whiteSpace: 'pre-wrap' }}>{itemAddError}</Typography>
                  </AutoCloseAlert>
                )}
              </Box>
            )}
            {successMessage && (
              <AutoCloseAlert
                open={successAutoCloseAlertOpen}
                handleClose={onSuccessAutoAlertClose}
                severity="success"
                autoHideDuration={8000}
                style={{ width: '100%', marginTop: '1rem' }}
              >
                <AlertTitle>Success</AlertTitle>
                {successMessage}
              </AutoCloseAlert>
            )}
            {errorMessage && (
              <AutoCloseAlert
                open={errorAutoCloseAlertOpen}
                handleClose={onErrorAutoAlertClose}
                autoHideDuration={15000}
                severity="error"
                style={{ width: '100%', marginTop: '1rem' }}
              >
                <AlertTitle>Error...</AlertTitle>
                <Typography style={{ whiteSpace: 'pre-wrap' }}>{errorMessage}</Typography>
              </AutoCloseAlert>
            )}
          </Box>
          <Box className={classes.summaryContainer}>
            <MaterialTable
              columns={[
                { title: 'Row', field: 'id', editable: 'never' },
                {
                  title: 'From Bin',
                  field: 'fromBin',
                  editable: 'always',
                },
                {
                  title: 'To Bin',
                  field: 'bin',
                  editable: 'always',
                },
                {
                  title: 'Sku',
                  field: 'sku',
                  editable: 'always',
                },
                {
                  title: 'Quantity to Move',
                  field: 'quantityToMove',
                  editable: 'always',
                },
              ]}
              editable={{
                isDeletable: undefined,
                isEditable: undefined,
                onRowAdd: undefined,
                onRowUpdate: undefined,
                onRowDelete: (oldData: any) => deleteItem(oldData),
              }}
              title="Items to Relocate"
              data={relocationRows.map(row => ({
                id: row.id,
                fromBin: row.currentItem.warehouseBin?.code,
                bin: row.toWarehouseBin.code,
                sku: row.currentItem.sku,
                quantityToMove: row.transferQuantity,
              }))}
              options={{ paging: false, sorting: false, search: false }}
            />
            <Button
              size="large"
              type="submit"
              ordinality="primary"
              disabled={!!!relocationRows.length || loading}
              style={{ width: '100%', marginTop: '1rem' }}
              onClick={handleSubmit}
            >
              Relocate Items
            </Button>
          </Box>
        </Box>
      </Box>
    </PageWrapper>
  );
};

export default MultiItemRelocator;
