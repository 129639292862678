import React, { useState, useEffect, useCallback } from 'react';
import { Modal } from '../../components/library';
import { Button, Radio, FormControlLabel, RadioGroup } from '@material-ui/core';
import TextInput from '../../components/catalog/TextInput';
import Loader from '../../components/loader';
import styled from 'styled-components';
import * as service from '../../services/catalog/services';
import { AxiosError } from 'axios';
import styles from '../../styledComponents';
import { ProductVariantSearchResult } from '../../services/catalog/types';
const { Error } = styles;

interface UpcSearchModalProps {
  open: boolean;
  handleClose: () => void;
  setSku: (value: string) => void;
  initialUpc?: string;
}

const StyledModalContents = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 600px;
`;

const StyledButton = styled.div`
  position: sticky;
  bottom: 0;
  background-color: white;
  width: 100%;
  min-height: 75px;
  display: flex;
  justify-content: center;
  border-top: 1px solid black;
`;

const StyledSearchForm = styled.div`
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledResults = styled.div`
  padding: 0 30px 30px;
`;

const StyledResultsTitle = styled.div`
  font-size: 22px;
`;

export const UpcSearchModal: React.FC<UpcSearchModalProps> = ({
  open,
  handleClose,
  setSku,
  initialUpc,
}) => {
  const [upc, setUpc] = useState(initialUpc ?? '');
  const [searchResults, setSearchResults] = useState<ProductVariantSearchResult[] | undefined>(
    undefined,
  );
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedSku, setSelectedSku] = useState<string>('');
  const searchByUpc = useCallback(async upc => {
    setError('');
    setLoading(true);
    setSelectedSku('');
    await service.fetchCatalogProductsSearch(
      { upc: upc, limit: 100 },
      {
        onSuccess: (pvSearchResults: any) => {
          const foundPvByUpc = pvSearchResults.results;
          if (!!foundPvByUpc && Array.isArray(foundPvByUpc)) {
            setSearchResults(foundPvByUpc);
          } else {
            setError(`Unable to find SKU with UPC: ${upc}`);
          }
        },
        catchFailure: (err: AxiosError | undefined) => {
          console.error('Search for variants by upc error:', err);
          setError('Search failed, try again or contact Tech');
        },
      },
    );
    setLoading(false);
  }, []);

  useEffect(() => {
    if (open && initialUpc) {
      searchByUpc(initialUpc);
    }
    // empty dependency array so as to ensure we only perform the search on the initial load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Modal label="upc-search-modal" open={open} handleClose={handleClose}>
      <StyledModalContents>
        <StyledSearchForm>
          <TextInput
            id="upc"
            label="Enter UPC to search for:"
            placeholder="e.g. 123-4567-891"
            value={upc}
            onChange={e => setUpc(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                searchByUpc(upc);
              }
            }}
            autoFocus
          />
          <Button
            type="submit"
            variant="contained"
            size="large"
            onClick={() => searchByUpc(upc)}
            disabled={!upc || !/\S/.test(upc)}
          >
            Submit
          </Button>
        </StyledSearchForm>
        {error && <Error>{error}</Error>}
        {loading && <Loader loading={loading} />}
        {searchResults && (
          <>
            <StyledResults>
              <StyledResultsTitle>Results ({searchResults.length}):</StyledResultsTitle>
              <RadioGroup
                aria-label="skus"
                name="skus"
                value={selectedSku}
                onChange={event => setSelectedSku(event.currentTarget.value)}
              >
                {searchResults?.map(result => (
                  <FormControlLabel
                    key={result.sku}
                    value={result.sku}
                    control={<Radio />}
                    label={result.sku}
                  />
                ))}
              </RadioGroup>
            </StyledResults>
            <StyledButton>
              <Button onClick={() => setSku(selectedSku)} disabled={!selectedSku}>
                Select SKU
              </Button>
            </StyledButton>
          </>
        )}
      </StyledModalContents>
    </Modal>
  );
};
