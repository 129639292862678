import React, { FC, useCallback, useEffect, useState } from 'react';
import axios from '../../utils/axios';
import MaterialTable from '@material-table/core';

import { Box, Checkbox, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { Button, LoaderOverlay, TextField } from '../../components/library';
import styledComponents from '../../styledComponents';
import { useInventory } from '../catalog/utils/useInventory';
import { UpcSearchModal } from '../service/UpcSearchModal';
import { Inventory, WarehouseBin } from '../../services/inventory/types';
import { API_URL } from '../../constants';
import { WarehouseBinAutocomplete } from '../../components/inventory/WarehouseBinAutocomplete';
import { AutoCloseAlert, useAutoCloseAlert } from '../../components/library/AutoCloseAlert';
import { AlertTitle } from '@mui/material';
import { getZoneNameById, getZoneParentById } from '../catalog/utils/inventory/utils';
import { ProductVariant } from '../../services/catalog/types';

export const CREATE_INVENTORY_ADJUSTMENT_ROUTE = '/inventory/inventory-adjustment/create';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      flexDirection: 'row',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    inputContainer: {
      width: '38%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      display: 'flex',
      flexDirection: 'column',
    },
    summaryContainer: {
      marginTop: theme.spacing(3),
      width: '60%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },

    submissionLookup: {
      borderTop: `6px solid ${theme.palette.primary.main}`,
    },
    submissionLink: {
      color: theme.palette.text.secondary,
    },
  }),
);

const { PageWrapper } = styledComponents;

const InlineTextField = styled(TextField)`
  display: inline-block;
`;

const CheckboxWrapper = styled.label`
  display: inline-block;
  cursor: pointer;
`;

interface RelocationRow {
  id: number;
  productVariant: ProductVariant;
  warehouseBin: WarehouseBin;
  quantityAdjusted: number;
}

const CreateInventoryAdjustment: FC<Partial<RouteComponentProps>> = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);

  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
  const {
    autoCloseAlertOpen: successAutoCloseAlertOpen,
    handleAutoCloseAlertOpen: handleSuccessAutoCloseAlertOpen,
    handleAutoCloseAlertClose: handleSuccessAutoCloseAlertClose,
  } = useAutoCloseAlert();
  const onSuccessAutoAlertClose = useCallback(() => {
    setSuccessMessage(undefined);
    handleSuccessAutoCloseAlertClose();
  }, [setSuccessMessage, handleSuccessAutoCloseAlertClose]);

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const {
    autoCloseAlertOpen: errorAutoCloseAlertOpen,
    handleAutoCloseAlertOpen: handleErrorAutoCloseAlertOpen,
    handleAutoCloseAlertClose: handleErrorAutoCloseAlertClose,
  } = useAutoCloseAlert();
  const onErrorAutoAlertClose = useCallback(() => {
    setErrorMessage(undefined);
    handleErrorAutoCloseAlertClose();
  }, [setErrorMessage, handleErrorAutoCloseAlertClose]);

  const [warehouseZoneId, setWarehouseZoneId] = useState<number | undefined>(undefined);
  const [disableLocationChange, setDisableLocationChange] = useState<boolean>(false);
  const [inventoryAdjustmentReasonId, setInventoryAdjustmentReasonId] = useState<
    number | undefined
  >(undefined);
  const [inventoryAdjusmentNote, setInventoryAdjusmentNote] = useState<string>('');

  const [binLocationOptions, setBinLocationOptions] = useState<WarehouseBin[]>([]);
  const [binTypeahead, setBinTypeahead] = useState<string>('');
  const [addItemBinLocked, setAddItemBinLocked] = useState<boolean>(false);

  // Used for UPC search
  const [addItemSku, setAddItemSku] = useState<string>('');
  // Details on the item being added to the list
  const [productVariant, setProductVariant] = useState<ProductVariant | undefined>();
  const [currentInvDetails, setCurrentInvDetails] = useState<Inventory[]>([]);
  const [addItemWarehouseBin, setAddItemWarehouseBin] = useState<WarehouseBin | undefined>(
    undefined,
  );
  const [addItemAdjustmentQuantity, setAddItemAdjustmentQuantity] = useState<string>('');

  const [itemAddError, setItemAddError] = useState<string | undefined>(undefined);
  const {
    autoCloseAlertOpen: itemErrorAutoCloseAlertOpen,
    handleAutoCloseAlertOpen: handleItemErrorAutoCloseAlertOpen,
    handleAutoCloseAlertClose: handleItemErrorAutoCloseAlertClose,
  } = useAutoCloseAlert();

  const onItemErrorAutoAlertClose = useCallback(() => {
    setItemAddError(undefined);
    handleItemErrorAutoCloseAlertClose();
  }, [setItemAddError, handleItemErrorAutoCloseAlertClose]);

  const [upcModalOpen, setUpcModalOpen] = useState(false);

  const [relocationRows, setRelocationRows] = useState<RelocationRow[]>([]);

  const {
    warehouseZones,
    warehouseBinsForZone,
    inventoryAdjustmentReasons,
    error: relocateHookError,
  } = useInventory({
    typeaheadVal: binTypeahead,
    zoneId: warehouseZoneId,
  });

  const [allowCreateInventory, setAllowCreateInventory] = useState<number[]>([]);
  useEffect(() => {
    const fetchFeatureToggle = async () => {
      const url = `${API_URL}/user/featureToggles/adjustmentIdsAllowInventoryCreate`;
      try {
        const response = await axios.get(url);
        if (response?.data) {
          const allowList =
            typeof response.data === 'string'
              ? JSON.parse(response.data).map((a: any) => parseInt(a, 10))
              : response.data.map((a: any) => parseInt(a, 10));
          setAllowCreateInventory(allowList);
        }
      } catch (err) {
        console.error(
          err?.response?.data?.message
            ? err.response.data.message
            : err?.message
            ? err?.message
            : 'An unknown error occurred',
        );
      }
    };

    fetchFeatureToggle();
  }, []);

  useEffect(() => {
    if (inventoryAdjustmentReasonId && warehouseZoneId && productVariant) {
      const preventInventoryCreation = !allowCreateInventory.includes(inventoryAdjustmentReasonId);
      let binLocations = warehouseBinsForZone;
      if (preventInventoryCreation) {
        binLocations = currentInvDetails
          .filter(
            (inv: Inventory) =>
              inv.quantity > 0 && inv.warehouseBin?.warehouseZoneId === warehouseZoneId,
          )
          .map((inv: Inventory) => inv.warehouseBin)
          .filter(wb => !!wb) as WarehouseBin[];
        if (binLocations.length === 0) {
          setItemAddError('SKU not found in inventory');
          handleItemErrorAutoCloseAlertOpen();
        }
      }
      setBinLocationOptions(binLocations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allowCreateInventory,
    productVariant,
    inventoryAdjustmentReasonId,
    warehouseZoneId,
    warehouseBinsForZone,
  ]);

  const handleSkuByUpc = (value: string) => {
    setUpcModalOpen(false);
    setAddItemSku(value);
  };
  const skuInputRef = React.useRef<HTMLInputElement>(null);

  const getCurrentSkuInventoryDetails = async () => {
    setItemAddError(undefined);
    handleItemErrorAutoCloseAlertClose();
    if (addItemSku) {
      try {
        await axios.get(`${API_URL}/inventory/skus/${addItemSku}`).then(res => {
          const productVariant = res?.data;
          if (!productVariant) {
            setItemAddError('SKU not found');
            handleItemErrorAutoCloseAlertOpen();
            return;
          }
          setProductVariant(productVariant);
          setCurrentInvDetails(productVariant.warehouseInventories);
        });
      } catch (err) {
        const errMsg = err?.response?.data?.message
          ? err.response.data.message
          : err?.message
          ? err?.message
          : 'An unknown error occurred';
        setItemAddError(errMsg);
        handleItemErrorAutoCloseAlertOpen();
      }
    }
  };

  const deleteItem = (xferObj: { id: number }): Promise<void> => {
    const { id } = xferObj;
    const xferArr = relocationRows;
    const newArr = xferArr
      .filter((x: any) => x.id !== id)
      .map((x: any, idx: number) => ({ ...x, id: idx }));
    setRelocationRows(newArr);
    return Promise.resolve();
  };

  const validateAddItem = (selectedInventoryDetails?: Inventory) => {
    if (!inventoryAdjustmentReasonId) {
      return 'Please select a reason code';
    }
    const preventInventoryCreation = !allowCreateInventory.includes(inventoryAdjustmentReasonId);
    if (preventInventoryCreation && !selectedInventoryDetails) {
      setItemAddError('SKU not found in inventory');
      handleItemErrorAutoCloseAlertOpen();
      return;
    }
    if (!addItemSku || !addItemWarehouseBin || !addItemAdjustmentQuantity) {
      return 'Please fill out all fields';
    }
    // Validate it is not already in the list
    if (
      relocationRows.find(
        row =>
          row.productVariant.sku === addItemSku && row.warehouseBin.id === addItemWarehouseBin.id,
      )
    ) {
      return `Item ${addItemSku} in bin ${addItemWarehouseBin.code} is already in the list`;
    }

    // Validate we aren't going to take the inventory of the sku to less than zero
    const addItemQuantityNumber = parseInt(addItemAdjustmentQuantity, 10);
    if (
      selectedInventoryDetails &&
      addItemQuantityNumber < 0 &&
      selectedInventoryDetails.quantity < Math.abs(addItemQuantityNumber)
    ) {
      return `You're adjustment will take the inventory of sku ${addItemSku} quantity which has ${selectedInventoryDetails.quantity} to less than zero with your adjustment ${addItemQuantityNumber}.`;
    }
    // Validate the bin is in the toZoneId
    if (addItemWarehouseBin.warehouseZoneId !== warehouseZoneId) {
      return `Bin ${addItemWarehouseBin.code} is not in the to location ${getZoneNameById(
        warehouseZones,
        warehouseZoneId,
      )}`;
    }
    return undefined;
  };

  const clearPageState = () => {
    setRelocationRows([]);
    setWarehouseZoneId(undefined);
    setInventoryAdjustmentReasonId(undefined);
    setInventoryAdjusmentNote('');
    setAddItemSku('');
    setAddItemWarehouseBin(undefined);
    setAddItemAdjustmentQuantity('');
    setProductVariant(undefined);
    handleErrorAutoCloseAlertOpen();
  };

  const setWarehouseZoneData = (zoneId: string) => {
    if (parseInt(zoneId, 10) === warehouseZoneId) {
      return;
    }
    setWarehouseZoneId(parseInt(zoneId));
    setAddItemWarehouseBin(undefined);
  };

  const clearItemForm = () => {
    setAddItemSku('');
    setAddItemAdjustmentQuantity('');
    if (!addItemBinLocked) {
      setAddItemWarehouseBin(undefined);
    }
    setProductVariant(undefined);
  };

  const addItem = () => {
    if (!productVariant) {
      setItemAddError('SKU not found');
      handleItemErrorAutoCloseAlertOpen();
      return;
    }
    const selectedInventoryDetails = currentInvDetails.find(
      inv => inv.warehouseBinId === addItemWarehouseBin?.id,
    );
    const errorMessage = validateAddItem(selectedInventoryDetails);
    if (errorMessage) {
      setItemAddError(errorMessage);
      handleItemErrorAutoCloseAlertOpen();
      return;
    }
    const newItems: RelocationRow[] = [
      ...relocationRows,
      {
        id: relocationRows.length,
        productVariant: productVariant,
        warehouseBin: addItemWarehouseBin as WarehouseBin,
        quantityAdjusted: parseInt(addItemAdjustmentQuantity, 10),
      },
    ];
    setRelocationRows(newItems as RelocationRow[]);
    clearItemForm();
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setLoading(true);
    if (!inventoryAdjusmentNote) {
      setErrorMessage('Please enter a note');
      handleErrorAutoCloseAlertOpen();
      setLoading(false);
      return;
    }
    // Logic to create inventory adjustment
    try {
      const inventoryAdjustmentItems = relocationRows.map(row => ({
        sku: row.productVariant.sku,
        warehouseBinId: row.warehouseBin?.id,
        quantityAdjusted: row.quantityAdjusted,
      }));
      const res = await axios.post(`${API_URL}/inventory/adjustment`, {
        warehouseZoneId,
        adjustmentReasonId: inventoryAdjustmentReasonId,
        notes: inventoryAdjusmentNote,
        inventoryAdjustmentItems,
      });
      // What is a good response with detail to show what was relocated?
      console.log('create inventory adjustment response', res);
      setSuccessMessage('Inventory Adjustment Created');
      handleSuccessAutoCloseAlertOpen();
      clearPageState();
    } catch (err) {
      const error = err?.response?.data?.message
        ? err.response.data.message
        : err?.message
        ? err?.message
        : 'An unknown error occurred';
      setErrorMessage(error);
      handleErrorAutoCloseAlertOpen();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (relocationRows.length > 0) {
      setDisableLocationChange(true);
    } else {
      setDisableLocationChange(false);
    }
  }, [relocationRows.length]);

  const theme = useTheme();
  return (
    <PageWrapper style={{ overflow: 'hidden' }}>
      <LoaderOverlay loading={loading} />
      <Box
        style={{
          width: '100%',
          maxWidth: '1500px',
          marginTop: theme.spacing(8),
        }}
      >
        <LoaderOverlay loading={loading} />
        <Typography variant="h4" gutterBottom>
          Create Inventory Adjustment
        </Typography>
        <Typography variant="caption" gutterBottom>
          Note: Drivetrain is now the source of truth for inventory, all Inventory Adjustments
          should be created here. Drivetrain will manage inventory in Netsuite and Shopify as
          needed.
        </Typography>
        <Box
          className={classes.formContainer}
          style={{
            width: '100%',
            marginTop: theme.spacing(3),
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box className={classes.inputContainer}>
            <Box
              style={{
                marginTop: theme.spacing(3),
                padding: theme.spacing(3),
                border: `5px solid ${theme.palette.divider}`,
              }}
            >
              <Box style={{ marginTop: theme.spacing(3) }}>
                <InlineTextField
                  id="toWarehouseZone"
                  name="toWarehouseZoneId"
                  value={warehouseZoneId || ''}
                  disabled={disableLocationChange}
                  onChange={e => setWarehouseZoneData(e.target.value)}
                  options={warehouseZones?.map(zone => ({
                    label: `${getZoneParentById(warehouseZones, zone.parentWarehouseZoneId)}${
                      zone.name
                    }`,
                    value: zone.id,
                  }))}
                  placeholder="Select Warehouse Zone"
                  label="Warehouse Zone"
                  variant="outlined"
                />
              </Box>
              <Box style={{ marginTop: theme.spacing(3) }}>
                <InlineTextField
                  id="inventoryAdjutmentReason"
                  name="inventoryAdjutmentReasonId"
                  value={inventoryAdjustmentReasonId || ''}
                  onChange={e => setInventoryAdjustmentReasonId(parseInt(e.target.value, 10))}
                  options={inventoryAdjustmentReasons?.map(reason => ({
                    label: reason.code,
                    value: reason.id,
                  }))}
                  placeholder="Select Reason Code"
                  label="Reason Code"
                  variant="outlined"
                  disabled={disableLocationChange}
                />
              </Box>
              <Box style={{ marginTop: theme.spacing(3) }}>
                <InlineTextField
                  id="inventoryAdjusmentNote"
                  name="inventoryAdjusmentNote"
                  value={inventoryAdjusmentNote || ''}
                  onChange={e => setInventoryAdjusmentNote(e.target.value)}
                  placeholder="Enter a note"
                  label="Notes"
                  variant="outlined"
                  disabled={disableLocationChange}
                />
              </Box>
              {disableLocationChange && (
                <Box style={{ marginTop: theme.spacing(3) }}>
                  Locations are locked because items have been added to the list.
                </Box>
              )}
            </Box>
            {warehouseZoneId && inventoryAdjustmentReasonId && (
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  marginTop: theme.spacing(3),
                  padding: theme.spacing(3),
                  border: `5px solid ${theme.palette.divider}`,
                }}
              >
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <InlineTextField
                    tabIndex={0}
                    autoFocus
                    id="SkuRelocateSKU"
                    name="sku"
                    value={addItemSku}
                    onChange={e => setAddItemSku(e.target.value)}
                    placeholder="Enter SKU"
                    label="SKU"
                    variant="outlined"
                    inputRef={skuInputRef}
                    onBlur={getCurrentSkuInventoryDetails}
                  />
                  <span style={{ padding: '0 1rem' }}>or</span>
                  <Button
                    onClick={() => setUpcModalOpen(true)}
                    ordinality="secondary"
                    tabIndex={-1}
                  >
                    Search by UPC
                  </Button>
                  {upcModalOpen && (
                    <UpcSearchModal
                      open={upcModalOpen}
                      handleClose={() => setUpcModalOpen(false)}
                      setSku={handleSkuByUpc}
                    />
                  )}
                </Box>
                <Box
                  style={{
                    marginTop: theme.spacing(3),
                    gap: theme.spacing(2),
                    display: 'flex',
                    alignItems: 'flex-start',
                    width: '100%',
                  }}
                >
                  <Box style={{ width: '50%' }}>
                    <WarehouseBinAutocomplete
                      style={{ width: '100%' }}
                      id="InventoryAdjusmentItemBin"
                      selectedBin={addItemWarehouseBin}
                      label="Warehouse Bin"
                      placeholder="Select the Bin"
                      options={binLocationOptions.filter(
                        bin => bin.warehouseZoneId === warehouseZoneId,
                      )}
                      typeaheadVal={binTypeahead}
                      onTypeaheadChange={setBinTypeahead}
                      onBinChange={e => {
                        setAddItemWarehouseBin((e as unknown) as WarehouseBin);
                        skuInputRef.current?.focus();
                      }}
                    />
                    <CheckboxWrapper>
                      <Checkbox
                        checked={addItemBinLocked}
                        onChange={() => setAddItemBinLocked(!addItemBinLocked)}
                        tabIndex={-1}
                      />
                      Lock Bin
                    </CheckboxWrapper>
                  </Box>
                </Box>
                <Box
                  style={{
                    marginTop: theme.spacing(1),
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    justifyContent: 'space-between',
                    width: '45%',
                  }}
                >
                  <InlineTextField
                    id="InventoryAdjusmentItemQuantity"
                    name="quantity"
                    type="number"
                    value={addItemAdjustmentQuantity}
                    onChange={e => setAddItemAdjustmentQuantity(e.target.value)}
                    placeholder="Enter Quantity"
                    label="Quantity"
                    variant="outlined"
                  />
                </Box>
                <Box style={{ marginTop: theme.spacing(1) }}>
                  <Button
                    size="large"
                    type="submit"
                    ordinality="primary"
                    disabled={
                      loading ||
                      !addItemSku ||
                      !addItemWarehouseBin ||
                      !addItemAdjustmentQuantity ||
                      !!relocateHookError
                    }
                    style={{ width: '100%', marginTop: '1rem' }}
                    onClick={addItem}
                  >
                    Add Item
                  </Button>
                </Box>
                {itemAddError && (
                  <AutoCloseAlert
                    open={itemErrorAutoCloseAlertOpen}
                    handleClose={onItemErrorAutoAlertClose}
                    autoHideDuration={15000}
                    severity="error"
                    style={{ width: '100%', marginTop: '1rem' }}
                  >
                    <AlertTitle>Error</AlertTitle>
                    {itemAddError}
                  </AutoCloseAlert>
                )}
              </Box>
            )}
            {successMessage && (
              <AutoCloseAlert
                open={successAutoCloseAlertOpen}
                handleClose={onSuccessAutoAlertClose}
                severity="success"
                autoHideDuration={8000}
                style={{ width: '100%', marginTop: '1rem' }}
              >
                <AlertTitle>Success</AlertTitle>
                {successMessage}
              </AutoCloseAlert>
            )}
            {errorMessage && (
              <AutoCloseAlert
                open={errorAutoCloseAlertOpen}
                handleClose={onErrorAutoAlertClose}
                autoHideDuration={15000}
                severity="error"
                style={{ width: '100%', marginTop: '1rem' }}
              >
                <AlertTitle>Error</AlertTitle>
                {errorMessage}
              </AutoCloseAlert>
            )}
          </Box>
          <Box className={classes.summaryContainer}>
            <MaterialTable
              columns={[
                { title: 'Row', field: 'id', editable: 'never' },
                {
                  title: 'Sku',
                  field: 'sku',
                  editable: 'always',
                },
                {
                  title: 'Warehouse Bin',
                  field: 'bin',
                  editable: 'always',
                },
                {
                  title: 'Adjustment Quantity',
                  field: 'quantityToAdjust',
                  editable: 'always',
                },
              ]}
              editable={{
                isDeletable: undefined,
                isEditable: undefined,
                onRowAdd: undefined,
                onRowUpdate: undefined,
                onRowDelete: (oldData: any) => deleteItem(oldData),
              }}
              title="Adjustment Items"
              data={relocationRows.map(row => ({
                id: row.id,
                bin: row.warehouseBin.code,
                sku: row.productVariant.sku,
                quantityToAdjust: row.quantityAdjusted,
              }))}
              options={{ paging: false, sorting: false, search: false }}
            />
            <Button
              size="large"
              type="submit"
              ordinality="primary"
              disabled={!!!relocationRows.length || loading}
              style={{ width: '100%', marginTop: '1rem' }}
              onClick={handleSubmit}
            >
              Create Inventory Adjustment
            </Button>
          </Box>
        </Box>
      </Box>
    </PageWrapper>
  );
};

export default CreateInventoryAdjustment;
