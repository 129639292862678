import React, { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';
import axios from '../../../utils/axios';
import { API_URL, netsuiteFields } from '../../../constants';
import styles from '../../../styledComponents/';
import { UpcSearchModal } from '../UpcSearchModal';
import {
  ErrorMessage,
  TextField,
  Button,
  LoaderOverlay,
  Checkbox,
} from '../../../components/library';
import { Bin, NetSuiteInventory } from '../../../services/service/types';
import { useRelocate } from '../../catalog/utils/useRelocate';
import { BinAutocomplete } from '../../../components/service/BinAutocomplete';
import { AutoCloseAlert, useAutoCloseAlert } from '../../../components/library/AutoCloseAlert';
import { AlertTitle, Typography } from '@mui/material';

const { PageWrapper, HeaderWrapper, FancyForm, FlexWrapper, FlexRow } = styles;

const InlineTextField = styled(TextField)`
  display: inline-block;
`;

const LeftAlignedFlexRow = styled(FlexRow)`
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const CheckboxWrapper = styled.label`
  display: inline-block;
  cursor: pointer;
  min-width: 160px;
`;

const StyledSkuSearch = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

interface SkuRelocatorProps {
  sku?: string;
}

const Relocator: FC<Partial<RouteComponentProps> & SkuRelocatorProps> = ({
  location: windowLocation,
  sku: propSku,
}) => {
  const skuParam = windowLocation ? new URLSearchParams(windowLocation.search).get('sku') : null;
  // Can pass in initial sku as a query param or prop, prefer query param
  const initialSku = skuParam || propSku || '';

  const [loading, setLoading] = useState<boolean>(false);
  const [upcModalOpen, setUpcModalOpen] = useState(false);

  const [locationLocked, setLocationLocked] = useState<boolean>(false);
  const [binLocked, setBinLocked] = useState<boolean>(false);
  const [wipStateLocked, setWipStateLocked] = useState<boolean>(false);

  const [sku, setSku] = useState<string>(initialSku);
  const [bin, setBin] = useState<Bin | undefined>(undefined);
  const [binTypeahead, setBinTypeahead] = useState<string>('');
  const [locationId, setLocationId] = useState<number | undefined>(undefined);
  const [wipStateId, setWipStateId] = useState<string>('');
  const [currentInventoryDetails, setCurrentInventoryDetails] = useState<
    NetSuiteInventory | undefined
  >(undefined);

  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
  const {
    autoCloseAlertOpen: successAutoCloseAlertOpen,
    handleAutoCloseAlertOpen: handleSuccessAutoCloseAlertOpen,
    handleAutoCloseAlertClose: handleSuccessAutoCloseAlertClose,
  } = useAutoCloseAlert();
  const onSuccessAutoAlertClose = useCallback(() => {
    setSuccessMessage(undefined);
    handleSuccessAutoCloseAlertClose();
  }, [setSuccessMessage, handleSuccessAutoCloseAlertClose]);

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const {
    autoCloseAlertOpen: errorAutoCloseAlertOpen,
    handleAutoCloseAlertOpen: handleErrorAutoCloseAlertOpen,
    handleAutoCloseAlertClose: handleErrorAutoCloseAlertClose,
  } = useAutoCloseAlert();
  const onErrorAutoAlertClose = useCallback(() => {
    setSuccessMessage(undefined);
    handleErrorAutoCloseAlertClose();
  }, [setSuccessMessage, handleErrorAutoCloseAlertClose]);

  const {
    locations = [],
    binsForLocation = [],
    wipStates = [],
    error: relocateHookError,
  } = useRelocate({
    typeaheadVal: binTypeahead,
    locationId,
  });

  const clearState = () => {
    if (!locationLocked) setLocationId(undefined);
    if (!binLocked) setBin(undefined);
    if (!wipStateLocked) setWipStateId('');
    setSku('');
    setLoading(false);
    setErrorMessage(undefined);
    handleErrorAutoCloseAlertOpen();
    setCurrentInventoryDetails(undefined);
  };

  const getCurrentSkuInventoryDetails = async () => {
    if (sku) {
      try {
        await axios
          .get(`${API_URL}/service/inventoryRecords`, {
            params: { sku },
          })
          .then(res => {
            const currentInvDetails = res?.data?.[0];
            setCurrentInventoryDetails(currentInvDetails);
          });
      } catch (err) {
        console.error(
          err?.response?.data?.message
            ? err.response.data.message
            : err?.message
            ? err?.message
            : 'An unknown error occurred',
        );
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      setLoading(true);
      let successMessage: string;

      if (!bin || !locationId) {
        throw new Error('A location and bin are required');
      }

      await axios
        .post(`${API_URL}/service/relocate`, {
          sku,
          toLocationId: locationId,
          toBin: bin.code,
          toWipStateId: wipStateId,
        })
        .then(async () => {
          successMessage = `${sku} has been moved to bin ${bin?.code}`;
          if (wipStateId) {
            await axios
              .post(`${API_URL}/service/updateItem`, {
                sku,
                fieldId: netsuiteFields.WIPSTATE,
                value: wipStateId,
              })
              .then(() => {
                successMessage += ` and WIP State updated to ${
                  wipStates.find(wipState => wipState.id === wipStateId)?.value
                }`;
              })
              .catch(err => {
                let message = `${successMessage} - but the WIP state did not get updated :: unknown error`;
                if (err.response)
                  message = `${successMessage} - but the WIP state did not get updated with error :: ${err.response.data.message}`;
                throw new Error(message);
              });
          }
        })
        .finally(() => {
          setSuccessMessage(successMessage);
          handleSuccessAutoCloseAlertOpen();
          clearState();
        });
    } catch (err) {
      setErrorMessage(
        err?.response?.data?.message
          ? err.response.data.message
          : err?.message
          ? err?.message
          : 'An unknown error occurred',
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSkuByUpc = (value: string) => {
    setUpcModalOpen(false);
    setSku(value);
  };

  const skuInputRef = React.useRef<HTMLInputElement>(null);

  return (
    <PageWrapper>
      <LoaderOverlay loading={loading} />
      <HeaderWrapper>Relocator</HeaderWrapper>
      <FlexWrapper width="500px">
        <FancyForm onSubmit={handleSubmit}>
          <LeftAlignedFlexRow>
            <InlineTextField
              id="SkuRelocateLocation"
              name="locationId"
              value={locationId || ''}
              onChange={e => setLocationId(parseInt(e.target.value))}
              options={locations.map((obj: { id: number; code: string }) => ({
                label: obj.code,
                value: obj.id,
              }))}
              placeholder="Location"
              label="Location"
              variant="outlined"
            />
            <CheckboxWrapper>
              <Checkbox
                checked={locationLocked}
                onChange={() => setLocationLocked(!locationLocked)}
                name="locationLocked"
                tabIndex={-1}
              />
              Lock Location
            </CheckboxWrapper>
          </LeftAlignedFlexRow>
          {currentInventoryDetails && (
            <LeftAlignedFlexRow
              style={{
                marginTop: '-0.5rem',
                marginBottom: '1rem',
                marginRight: 0,
                marginLeft: '1rem',
                padding: 0,
              }}
            >
              <Typography variant="caption">
                Current Location: {currentInventoryDetails?.locationCode}
              </Typography>
            </LeftAlignedFlexRow>
          )}
          <LeftAlignedFlexRow>
            <BinAutocomplete
              style={{ width: '100%' }}
              id="SKURelocateModalBin"
              selectedBin={bin}
              options={binsForLocation}
              typeaheadVal={binTypeahead}
              onTypeaheadChange={setBinTypeahead}
              onBinChange={e => {
                setBin(e);
                skuInputRef.current?.focus();
              }}
            />
            <CheckboxWrapper>
              <Checkbox
                checked={binLocked}
                onChange={() => setBinLocked(!binLocked)}
                tabIndex={-1}
              />
              Lock Bin
            </CheckboxWrapper>
          </LeftAlignedFlexRow>
          {currentInventoryDetails && (
            <LeftAlignedFlexRow
              style={{
                marginTop: '-0.5rem',
                marginBottom: '1rem',
                marginRight: 0,
                marginLeft: '1rem',
                padding: 0,
              }}
            >
              <Typography variant="caption">
                Current Bin: {currentInventoryDetails?.binCode}
              </Typography>
            </LeftAlignedFlexRow>
          )}
          <LeftAlignedFlexRow>
            <StyledSkuSearch>
              <InlineTextField
                id="SkuRelocateSKU"
                name="sku"
                value={sku}
                onChange={e => setSku(e.target.value)}
                placeholder="Enter SKU"
                label="SKU"
                variant="outlined"
                inputRef={skuInputRef}
                onBlur={getCurrentSkuInventoryDetails}
              />
              <span style={{ padding: '0 1rem' }}>or</span>
              <Button onClick={() => setUpcModalOpen(true)} ordinality="secondary" tabIndex={-1}>
                Search by UPC
              </Button>
              {upcModalOpen && (
                <UpcSearchModal
                  open={upcModalOpen}
                  handleClose={() => setUpcModalOpen(false)}
                  setSku={handleSkuByUpc}
                />
              )}
            </StyledSkuSearch>
          </LeftAlignedFlexRow>
          <LeftAlignedFlexRow>
            <InlineTextField
              id="inspectionRelocateModalWIPStateId"
              name="WIPStateId"
              value={wipStateId || ''}
              onChange={e => setWipStateId(e.target.value)}
              placeholder="WIP State"
              label="WIP State"
              variant="outlined"
              options={wipStates.map((obj: any) => ({ label: obj.value, value: obj.id }))}
            />
            <CheckboxWrapper>
              <Checkbox
                checked={wipStateLocked}
                onChange={() => setWipStateLocked(!wipStateLocked)}
                tabIndex={-1}
              />
              Lock WIP State
            </CheckboxWrapper>
          </LeftAlignedFlexRow>
          {currentInventoryDetails && (
            <LeftAlignedFlexRow
              style={{
                marginTop: '-0.5rem',
                marginBottom: '1rem',
                marginRight: 0,
                marginLeft: '1rem',
                padding: 0,
              }}
            >
              <Typography variant="caption">
                Current WIP State: {currentInventoryDetails?.wipState}
              </Typography>
            </LeftAlignedFlexRow>
          )}

          <Button
            size="large"
            type="submit"
            ordinality="primary"
            disabled={
              loading || !locationId || !sku || (!bin && !wipStateId) || !!relocateHookError
            }
            style={{ width: '100%', marginTop: '1rem' }}
          >
            Relocate
          </Button>
          <ErrorMessage error={relocateHookError} style={{ width: '100%', marginTop: '1rem' }} />
          {successMessage && (
            <AutoCloseAlert
              open={successAutoCloseAlertOpen}
              handleClose={onSuccessAutoAlertClose}
              severity="success"
              autoHideDuration={8000}
              style={{ width: '100%', marginTop: '1rem' }}
            >
              <AlertTitle>Success</AlertTitle>
              {successMessage}
            </AutoCloseAlert>
          )}
          {errorMessage && (
            <AutoCloseAlert
              open={errorAutoCloseAlertOpen}
              handleClose={onErrorAutoAlertClose}
              autoHideDuration={15000}
              severity="error"
              style={{ width: '100%', marginTop: '1rem' }}
            >
              <AlertTitle>Error</AlertTitle>
              {errorMessage}
            </AutoCloseAlert>
          )}
        </FancyForm>
      </FlexWrapper>
    </PageWrapper>
  );
};

export default Relocator;
