import React, { useEffect, useContext, useState, useMemo } from 'react';
import { serviceTicketContext, useUserName } from '../../_shared';
import {
  EditButtonWrapper,
  LeftCol,
  RightColWrapper,
  RightColContent,
  DetailLabel,
  Detail,
  WidthConstrainedDetail,
} from './styled';
import { Tooltip, Box, makeStyles, createStyles, Theme, Divider } from '@material-ui/core';
import { AttachMoney, MoneyOff } from '@material-ui/icons';
import { Button } from '../../../../../components/library';
import { InspectionStatusBadge } from '../../InspectionStatusBadge';
import { useInspectionProduct } from './useInspectionProduct';
import { SUBMISSION_DETAIL_ROUTE } from '../../../../tradeups/SubmissionDetailView';
import { EditServiceTicketModal } from '../../EditServiceTicketModal/EditServiceTicketModal';
import PencilIcon from '@material-ui/icons/Edit';
import { pageStateContext } from '../../../../_shared';
import { InventoryRecord } from '../../_shared/hooks/useInventoryRecords';
import { calculateHoursSinceDate, convertHoursToUnitsOfTime } from './ServiceTicketHeader.utils';
import { SubmissionStatusIds } from '../../../../../constants';
import { ServicePlanOperatorsList } from './ServicePlanOperatorsList';
import { StickyHeader } from '../../../../../components/library/StickyHeader/StickyHeader';
import { PARTNER_SUBMISSION_DETAIL_ROUTE } from '../../../../tradeups/partnerDetaiView';
import { Inventory } from '../../../../../services/inventory/types';
import { getZoneNameFromZone } from '../../../../catalog/utils/inventory/utils';

interface ServiceTicketHeaderProps {
  inventoryRecords: InventoryRecord[] | Inventory[] | undefined;
  unsavedData: boolean;
  isBikeStolen: boolean;
}

type PaymentStatus = 'paid' | 'prepaid' | 'unpaid';

// todo: unit test for this
export function getFormattedDate(date: Date) {
  // months are 0-indexed
  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : `0${month}`;

  // days are 1-indexed
  let day = date.getDate().toString();
  day = day.length > 1 ? day : `0${day}`;

  return `${month}/${day}/${date.getFullYear()}`;
}

const emptyVal = '--';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paymentStatus: {
      padding: theme.spacing(0.5),
      marginLeft: theme.spacing(0.5),
      borderRadius: '50%',
      width: '32px',
      height: '32px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    prePayment: {
      backgroundColor: theme.palette.action.disabledBackground,
      color: theme.palette.action.disabled,
    },
    paid: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    notPaid: {
      backgroundColor: theme.palette.error.background,
      color: theme.palette.error.main,
    },
  }),
);

export const ServiceTicketHeader: React.FC<ServiceTicketHeaderProps> = ({
  inventoryRecords,
  unsavedData,
  isBikeStolen,
}) => {
  const inspection = useContext(serviceTicketContext)?.inspectionData?.inspection;

  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>();

  // todo: Remove as part of TP-4180
  const [showOperatorHistory] = useState(true);

  // itemProcurement won't exist on pre-2022 inspections so we have to be able to fall back to the inspection serial
  const {
    serialNumber: procurementSerial,
    receivedAt,
    sybSubmissionId,
    sybSubmission,
    partnerSubmissionId,
    productVariantId,
  } = inspection?.itemProcurement ?? {};

  // todo: move to useInspection and write tests
  // todo: Remove as part of TP-4180
  const lastInspectionDate: Date | undefined = useMemo(() => {
    if (!inspection) return undefined;
    const inspectionPointCompletionTimes = inspection.inspectionPoints.map(p =>
      new Date(p.inspectedAt ?? 0).getTime(),
    );
    // the `inspection.created` fallback is necessary because legacy inspections
    // won't have values in `inspectionPoint.inspectedAt`
    return new Date(
      Math.max(...inspectionPointCompletionTimes, new Date(inspection.created).getTime()),
    );
  }, [inspection]);

  const workingTimeElapsed = useMemo(() => {
    if (!receivedAt) return undefined;
    return convertHoursToUnitsOfTime(calculateHoursSinceDate(receivedAt));
  }, [receivedAt]);

  useEffect(() => {
    if (sybSubmission) {
      if (sybSubmission?.statusId === SubmissionStatusIds.COMPLETED) {
        setPaymentStatus('paid');
      } else if (sybSubmission?.statusId === SubmissionStatusIds.DECLINED_BUYER) {
        setPaymentStatus('unpaid');
      } else {
        setPaymentStatus('prepaid');
      }
    }
  }, [sybSubmission]);

  const [editModalOpen, setEditModalOpen] = useState(false);

  // punting on having these use ErrorBus until TP-3500
  const { userName: inspectorName, error: inspectorNameError } = useUserName(
    inspection?.inspectorId ?? null,
  );
  const { userName: mechanicName, error: mechanicNameError } = useUserName(
    inspection?.mechanicId ?? null,
  );
  const { product: inspectionProduct, error: inspectionProductError } = useInspectionProduct(
    productVariantId,
  );
  const {
    errorBus: { setError },
  } = useContext(pageStateContext);
  const {
    inspectionStatuses,
    inspectionData: { refreshInspectionData },
  } = useContext(serviceTicketContext);

  useEffect(() => setError('Inspector Name', inspectorNameError), [inspectorNameError, setError]);
  useEffect(() => setError('Mechanic Name', mechanicNameError), [mechanicNameError, setError]);
  useEffect(() => setError('Product Title', inspectionProductError), [
    inspectionProductError,
    setError,
  ]);

  const inventoryDelimited = useMemo(() => {
    if (!inventoryRecords) {
      return '';
    } else {
      return ((inventoryRecords as unknown) as Inventory[])
        .map(
          rec =>
            `${getZoneNameFromZone(rec.warehouseBin?.warehouseZone)}${
              rec.warehouseBin?.warehouseZone ? ' / ' : ''
            }${rec.warehouseBin?.code} (${rec.quantity})`,
        )
        .join(',');
    }
  }, [inventoryRecords]);

  const classes = useStyles();

  return (
    <>
      <StickyHeader
        title={inspection?.sku || ''}
        subtitle={
          !!inspectionProduct ? (
            <a
              href={`/catalog/products/${inspectionProduct.id}?sku=${inspection?.sku}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {inspectionProduct.displayTitle ?? '(no title)'}
            </a>
          ) : (
            ''
          )
        }
        size="large"
        px={8}
        actions={
          <Box display="flex" alignItems="center" gridGap={'8px'}>
            {inspection?.statusId && (
              <InspectionStatusBadge
                statusId={inspection.statusId}
                label={inspectionStatuses?.find(i => i.id === inspection?.statusId)?.name}
              />
            )}
            {paymentStatus == 'prepaid' && (
              <Tooltip title="Pre-Payment">
                <Box className={`${classes.paymentStatus} ${classes.prePayment}`}>
                  <AttachMoney />
                </Box>
              </Tooltip>
            )}
            {paymentStatus == 'paid' && (
              <Tooltip title="Paid">
                <Box className={`${classes.paymentStatus} ${classes.paid}`}>
                  <AttachMoney />
                </Box>
              </Tooltip>
            )}
            {paymentStatus == 'unpaid' && (
              <Tooltip title="Not-Paid">
                <Box className={`${classes.paymentStatus} ${classes.notPaid}`}>
                  <MoneyOff />
                </Box>
              </Tooltip>
            )}
          </Box>
        }
        description={inspection?.serialNumber}
        descriptionStickyOnly
      />

      <Box px={8} display="flex" justifyContent="space-between" pb={8} bgcolor="white" zIndex={3}>
        <LeftCol>
          <Detail>
            <DetailLabel>Catalogued Serial Number: </DetailLabel>
            {procurementSerial}
          </Detail>
          <Detail>
            <DetailLabel>Inspected Serial Number: </DetailLabel>
            {inspection?.serialNumber}
          </Detail>
          <Detail>
            <DetailLabel>Inventory Location:</DetailLabel>
            {inventoryDelimited}
          </Detail>
          {sybSubmissionId && (
            <Detail>
              <DetailLabel>SYB Submission: </DetailLabel>
              <a
                href={`${SUBMISSION_DETAIL_ROUTE}/${sybSubmissionId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {sybSubmissionId}
              </a>
            </Detail>
          )}
          {partnerSubmissionId && (
            <Detail>
              <DetailLabel>Partner Submission: </DetailLabel>
              <a
                href={`${PARTNER_SUBMISSION_DETAIL_ROUTE}/${partnerSubmissionId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {partnerSubmissionId}
              </a>
            </Detail>
          )}
          {inspection?.notes && (
            <WidthConstrainedDetail>
              <DetailLabel>Inspector Notes: </DetailLabel>
              {inspection?.notes}
            </WidthConstrainedDetail>
          )}
          {inspection?.mechanicNotes && (
            <WidthConstrainedDetail>
              <DetailLabel>Mechanic Notes: </DetailLabel>
              {inspection?.mechanicNotes}
            </WidthConstrainedDetail>
          )}
        </LeftCol>
        <RightColWrapper>
          <RightColContent>
            {workingTimeElapsed && (
              <Detail>
                <DetailLabel>Time since received: </DetailLabel>
                {`${workingTimeElapsed.days}d ${workingTimeElapsed.hours}h ${workingTimeElapsed.minutes}m`}
              </Detail>
            )}
            {/*  todo: Remove second part of ternary and ternary itself as part of TP-4180  */}
            {showOperatorHistory ? (
              <ServicePlanOperatorsList
                operators={inspection?.servicePlanOperators}
                itemProcurement={inspection?.itemProcurement}
              />
            ) : (
              <>
                <Detail>
                  <DetailLabel>Inspected by: </DetailLabel>
                  {inspectorName ?? emptyVal}
                </Detail>
                <Detail>
                  <DetailLabel>Inspection date: </DetailLabel>
                  {lastInspectionDate ? getFormattedDate(lastInspectionDate) : emptyVal}
                </Detail>
                {inspection?.mechanicId && (
                  <Detail>
                    <DetailLabel>Mechanic: </DetailLabel>
                    {mechanicName ?? emptyVal}
                  </Detail>
                )}
              </>
            )}

            <EditButtonWrapper style={{ marginTop: '16px' }}>
              <Tooltip
                arrow
                placement="left"
                title={unsavedData ? 'Please save changes before editing details.' : ''}
              >
                <Button
                  ordinality="secondary"
                  onClick={() => setEditModalOpen(true)}
                  disabled={unsavedData || isBikeStolen}
                >
                  <>
                    Edit Details &ensp; <PencilIcon />
                  </>
                </Button>
              </Tooltip>
              {editModalOpen && (
                <EditServiceTicketModal
                  open={editModalOpen}
                  handleClose={() => setEditModalOpen(false)}
                  handleSuccess={() => {
                    setEditModalOpen(false);
                    refreshInspectionData();
                  }}
                />
              )}
            </EditButtonWrapper>
          </RightColContent>
        </RightColWrapper>
      </Box>
      <Divider />
    </>
  );
};
