import { WarehouseZone } from '../../../../services/inventory/types';
import { AutoBin } from './types';

const compare = (autoBin: string | number | undefined, current: string | number | undefined) => {
  return !autoBin || autoBin === current;
};

export const shouldApplyAutoBin = (
  autoBin: AutoBin,
  inspectionStatusId?: number,
  inventoryRecordBinCode?: string,
  selectedStatusId?: number,
) => {
  return (
    compare(autoBin.currentStatus, inspectionStatusId) &&
    compare(autoBin.currentBin, inventoryRecordBinCode) &&
    compare(autoBin.selectedStatus, selectedStatusId)
  );
};

export const getOperationsWarehouseZones = (zones?: WarehouseZone[]) =>
  zones?.filter(zone => ['operations', 'refurbished-goods'].includes(zone.inventoryType));
