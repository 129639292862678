import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';
import {
  Button,
  Modal,
  SmallModalContents,
  ModalBody,
  ModalHeader,
  TextField,
  ErrorMessage,
} from '../../../../components/library';
import { InspectionStatusEnum } from '../../../../services/service/types';
import parseYupErrors from '../../../../utils/parseYupErrors';
import { API_URL } from '../../../../constants';
import axios from '../../../../utils/axios';
import { AxiosResponse } from 'axios';
import { INSPECTION_SEARCH_ROUTE } from '../InspectionSearchView';
import { serviceTicketContext } from '../_shared';
import { pageStateContext } from '../../../_shared';
import { AutoBins } from './types';
import { getOperationsWarehouseZones, shouldApplyAutoBin } from './RelocateModal.utils';
import { useInventory } from '../../../catalog/utils/useInventory';
import { WarehouseBinAutocomplete } from '../../../../components/inventory/WarehouseBinAutocomplete';
import { Inventory, WarehouseBin } from '../../../../services/inventory/types';
import { useDTInventoryRecords } from '../_shared/hooks/useDTInventoryRecords';
import { getZoneParentById } from '../../../catalog/utils/inventory/utils';

const HeaderSku = styled.span`
  font-weight: bold;
`;

interface ValidationErrors {
  [key: string]: string;
}
interface RelocateModalProps {
  open: boolean;
  handleClose: () => any;
  handleSuccess: () => void;
  inventoryRecords?: Inventory[];
}

const formSchema = yup.object().shape({
  status: yup.number().required('Please select a new status for this inspection.'),
  bin: yup.object().required('Please select a target bin for this item.'),
});

const RECV_BIN = 'RECVHOLD1';
const INTAKE_BIN = 'BUF-INTAKE-1';
const WASH_BIN = 'BUF-WASH-1';
const SW_BIN = 'BUF-SW-1';
const CONS_BIN = 'BUF-CONS-1';
const LINE_BIN = 'BUF-LINE-1';
const OUTREACH_BIN = 'BUF-KO-OUTREACH';

const AUTO_BINS: AutoBins = [
  {
    currentStatus: InspectionStatusEnum['Intake Started'],
    updatedStatus: InspectionStatusEnum['Intake Started'],
    currentBin: INTAKE_BIN,
    updatedBin: WASH_BIN,
  },
  {
    currentStatus: InspectionStatusEnum['Intake Started'],
    updatedStatus: InspectionStatusEnum['Intake Started'],
    currentBin: RECV_BIN,
    updatedBin: WASH_BIN,
  },
  {
    currentStatus: InspectionStatusEnum['Intake Started'],
    updatedStatus: InspectionStatusEnum['Ready for Inspection'],
    currentBin: WASH_BIN,
    updatedBin: SW_BIN,
  },
  {
    currentStatus: InspectionStatusEnum['Inspection Started'],
    selectedStatus: InspectionStatusEnum['Ready for Parts'],
    updatedBin: CONS_BIN,
  },
  {
    currentStatus: InspectionStatusEnum['Inspection Started'],
    selectedStatus: InspectionStatusEnum['Ready for Line'],
    updatedBin: LINE_BIN,
  },
  {
    currentStatus: InspectionStatusEnum['Rejected'],
    updatedStatus: InspectionStatusEnum['Rejected'],
    updatedBin: OUTREACH_BIN,
  },
];

export const RelocateModalDT: React.FC<RelocateModalProps> = ({
  open,
  handleClose,
  handleSuccess,
  inventoryRecords,
}) => {
  const {
    inspectionData: { inspection },
    inspectionStatuses,
  } = useContext(serviceTicketContext);

  const {
    errorBus,
    showSuccess,
    setSuccessMessage,
    loadingBus: { setLoading },
  } = useContext(pageStateContext);

  const { refetchDTInventoryRecords } = useDTInventoryRecords({
    sku: inspection?.sku,
    errorBus,
  });

  const [zoneId, setZoneId] = useState<number | undefined>(undefined);
  const [statusId, setStatusId] = useState<number | undefined>(undefined);
  const [binTypeahead, setBinTypeahead] = useState<string>('');
  const [warehouseBin, setWarehouseBin] = useState<WarehouseBin | undefined>(undefined);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});
  const [error, setError] = useState<string>();

  const history = useHistory();

  const { warehouseZones, warehouseBinsForZone, error: relocateInventoryError } = useInventory({
    typeaheadVal: binTypeahead,
    zoneId: zoneId,
  });

  const opsWarehouseZones = getOperationsWarehouseZones(warehouseZones);

  const validate = async () => {
    let isValid = true;
    try {
      await formSchema.validate(
        { status: statusId, bin: warehouseBin },
        { abortEarly: false, context: { binsForLocation: warehouseBinsForZone } },
      );
    } catch (err) {
      isValid = false;
      const newErrors = parseYupErrors(err, { status: 'statusId' });
      setValidationErrors(newErrors);
    } finally {
      return isValid;
    }
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    // successful validation means bin and statusId are defined, but TS can't infer that
    if ((await validate()) && !!warehouseBin && !!statusId && !!inspection) {
      try {
        setLoading('RelocateModal', true);
        const response: void | AxiosResponse = await axios.put(
          `${API_URL}/service/inspections/${inspection.id}/status/${statusId}/relocate`,
          {
            sku: inspection.sku,
            toWarehouseZoneId: zoneId,
            toWarehouseBinCode: warehouseBin.code,
          },
        );

        const submissionCompleted = response?.data?.submissionCompleted;
        if (submissionCompleted) {
          setSuccessMessage(
            'The service plan was saved and relocated, and the submission was completed.',
          );
          refetchDTInventoryRecords();
        } else {
          setSuccessMessage('The service plan was saved and relocated.');
        }
        showSuccess();
        handleSuccess();

        const redirectTodSearch =
          [
            InspectionStatusEnum['Intake Started'],
            InspectionStatusEnum['Inspection Started'],
            InspectionStatusEnum['Ready for Master Tech'],
            InspectionStatusEnum['Ready for Line'],
            InspectionStatusEnum['Ready for Parts'],
          ].includes(inspection.statusId) && statusId !== inspection.statusId;

        if (redirectTodSearch) {
          setTimeout(() => history.push(INSPECTION_SEARCH_ROUTE), 10000);
        }
      } catch (err) {
        setError(err.response ? err.response.data.message : 'An unknown error occurred');
      } finally {
        setLoading('RelocateModal', false);
      }
    }
  };

  useEffect(() => {
    AUTO_BINS.some(autoBin => {
      if (
        shouldApplyAutoBin(
          autoBin,
          inspection?.statusId,
          inventoryRecords?.[0]?.warehouseBin?.code,
          statusId,
        )
      ) {
        if (autoBin.updatedStatus && !statusId) {
          setStatusId(autoBin.updatedStatus);
        }
        if (autoBin.updatedBin) {
          setBinTypeahead(autoBin.updatedBin);
        }
        return true;
      }
      return false;
    });
  }, [inspection, statusId, inventoryRecords]);

  useEffect(() => {
    warehouseBinsForZone.length === 1 && setWarehouseBin(warehouseBinsForZone[0]);
  }, [warehouseBinsForZone]);

  if (!inspection) {
    return null;
  }

  return (
    <Modal open={open} handleClose={handleClose} label="relocate-modal">
      <SmallModalContents>
        <ModalHeader>
          Update DT location for <HeaderSku>{inspection.sku}</HeaderSku>
        </ModalHeader>
        <ModalBody>
          {relocateInventoryError && <ErrorMessage error={relocateInventoryError} />}
          {error && <ErrorMessage error={error} />}
          <TextField
            id="inspectionRelocateModalStatus"
            value={statusId || ''}
            onChange={e => setStatusId(parseInt(e.target.value))}
            placeholder="Status"
            label="Status"
            variant="outlined"
            options={inspectionStatuses?.map((status: any) => ({
              label: status.name,
              value: status.id,
            }))}
            error={!!validationErrors.statusId}
            helperText={validationErrors.statusId}
          />
          <TextField
            id="ServiceUpdateWarehouseZoneId"
            name="warehouseZoneId"
            value={zoneId || ''}
            onChange={e => setZoneId(parseInt(e.target.value))}
            options={opsWarehouseZones?.map(zone => ({
              label: `${getZoneParentById(warehouseZones, zone.parentWarehouseZoneId)}${zone.name}`,
              value: zone.id,
            }))}
            placeholder="Select Warehouse Zone"
            label="Warehouse Zone"
            variant="outlined"
          />

          {/* Add a location selection - any operations or refurbished goods location that is active */}
          <WarehouseBinAutocomplete
            style={{ width: '100%' }}
            id="SKURelocateModalBin"
            selectedBin={warehouseBin}
            options={warehouseBinsForZone}
            typeaheadVal={binTypeahead}
            onTypeaheadChange={setBinTypeahead}
            onBinChange={setWarehouseBin}
            errorMsg={validationErrors.bin}
          />
          <Button size="large" ordinality="primary" type="submit" onClick={handleSubmit}>
            Relocate
          </Button>
        </ModalBody>
      </SmallModalContents>
    </Modal>
  );
};
