import { API_URL } from '../../constants';
// import axios from '../../utils/axios';
import { RestResponse, axiosGetWithCallbacks, CallbackOptions } from '../rest-utils';
import { InventoryAdjustmentReason, WarehouseBin, WarehouseZone } from './types';
// import { InventoryRecord } from '../../pages/service/serviceTicket/_shared/hooks/useInventoryRecords';
// import { AxiosResponse } from 'axios';

export function getWarehouseZones(
  callbackOptions: CallbackOptions<WarehouseZone[]>,
): Promise<RestResponse<WarehouseZone[]>> {
  const url = `${API_URL}/inventory/zones`;
  return axiosGetWithCallbacks(url, undefined, callbackOptions);
}

export function getBinsByZone(
  zoneId: number,
  searchTerm: string,
  callbackOptions: CallbackOptions<WarehouseBin[]>,
): Promise<RestResponse<WarehouseBin[]>> {
  const params = new URLSearchParams();
  if (searchTerm) params.append('searchTerm', searchTerm);
  const url = `${API_URL}/inventory/zones/${zoneId}/bins?${params.toString()}`;
  return axiosGetWithCallbacks(url, undefined, callbackOptions);
}

export function getInventoryAdjustmentReasons(
  callbackOptions: CallbackOptions<InventoryAdjustmentReason[]>,
): Promise<RestResponse<InventoryAdjustmentReason[]>> {
  const url = `${API_URL}/inventory/inventoryAdjustmentReasons`;
  return axiosGetWithCallbacks(url, undefined, callbackOptions);
}
