import { API_URL } from '../../constants';
import axios from '../../utils/axios';
import { RestResponse, axiosGetWithCallbacks, CallbackOptions } from '../rest-utils';
import {
  Inspection,
  InspectionPointTypeCategory,
  InspectionsSearchQueryParams,
  InspectionsSearchResponse,
  InspectionStatus,
  WIPState,
  Bin,
  Location,
  User,
  ServicePlanRejectionReason,
} from './types';
import { Submission } from '../../pages/receiving/types';
import { InventoryRecord } from '../../pages/service/serviceTicket/_shared/hooks/useInventoryRecords';
import { AxiosResponse } from 'axios';
import { Inventory } from '../inventory/types';

export function searchInspections(
  params: InspectionsSearchQueryParams,
  callbackOptions?: CallbackOptions<InspectionsSearchResponse>,
): Promise<RestResponse<InspectionsSearchResponse>> {
  const url = `${API_URL}/service/inspections`;
  return axiosGetWithCallbacks(url, { params }, callbackOptions);
}

export function getInspectionStatuses(
  callbackOptions: CallbackOptions<InspectionStatus[]>,
): Promise<RestResponse<InspectionStatus[]>> {
  const url = `${API_URL}/service/inspectionStatuses`;
  return axiosGetWithCallbacks(url, undefined, callbackOptions);
}

export function getWIPStates(
  callbackOptions: CallbackOptions<WIPState[]>,
): Promise<RestResponse<WIPState[]>> {
  const url = `${API_URL}/service/wipStates`;
  return axiosGetWithCallbacks(url, undefined, callbackOptions);
}

export function getServiceLocations(
  callbackOptions: CallbackOptions<Location[]>,
): Promise<RestResponse<Location[]>> {
  const url = `${API_URL}/service/locations`;
  return axiosGetWithCallbacks(url, undefined, callbackOptions);
}

export function getBinsByLocation(
  locationId: number,
  searchTerm: string,
  callbackOptions: CallbackOptions<Bin[]>,
): Promise<RestResponse<Bin[]>> {
  const params = new URLSearchParams();
  if (searchTerm) params.append('searchTerm', searchTerm);
  if (locationId) params.append('locationId', `${locationId}`);
  const url = `${API_URL}/service/bins?${params.toString()}`;
  return axiosGetWithCallbacks(url, undefined, callbackOptions);
}

export function getInspection(
  inspectionId: string | number,
  callbackOptions: CallbackOptions<Inspection>,
): Promise<RestResponse<Inspection>> {
  const url = `${API_URL}/service/inspections/${inspectionId}`;
  return axiosGetWithCallbacks(url, undefined, callbackOptions);
}

export function getInspectionPointCategories(
  callbackOptions: CallbackOptions<InspectionPointTypeCategory[]>,
): Promise<RestResponse<InspectionPointTypeCategory[]>> {
  const url = `${API_URL}/service/inspectionPointCategories`;
  return axiosGetWithCallbacks(url, undefined, callbackOptions);
}

export function getUser(
  userId: number,
  callbackOptions: CallbackOptions<User>,
): Promise<RestResponse<User>> {
  const url = `${API_URL}/service/users/${userId}`;
  return axiosGetWithCallbacks(url, undefined, callbackOptions);
}

export function getInventoryRecords(
  sku: string,
  callbackOptions: CallbackOptions<InventoryRecord[]>,
): Promise<RestResponse<InventoryRecord[]>> {
  const url = `${API_URL}/service/inventoryRecords`;
  return axiosGetWithCallbacks(url, { params: { sku } }, callbackOptions);
}

export function getDTInventoryRecords(
  sku: string,
  callbackOptions: CallbackOptions<Inventory[]>,
): Promise<RestResponse<Inventory[]>> {
  const url = `${API_URL}/inventory/skus/${sku}/inventoryRecords`;
  return axiosGetWithCallbacks(url, { params: { sku } }, callbackOptions);
}

export function getSubmission(submissionId: number): Promise<AxiosResponse<Submission> | void> {
  const url = `${API_URL}/tradeup/v2/submissions/${submissionId}`;
  return axios.get(url);
}

export function updateInspection(
  inspectionId: number,
  payload: any,
): Promise<AxiosResponse<boolean> | void> {
  const url = `${API_URL}/service/inspections/${inspectionId}`;
  return axios.put(url, payload);
}

export function getServicePlanRejectionReasons(): Promise<AxiosResponse<
  ServicePlanRejectionReason[]
> | void> {
  const url = `${API_URL}/service/rejectionReasons`;
  return axios.get(url);
}
